import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import cn from 'classnames'

const ImageSlice = ({ slice: { span, image } }) => {
  const classes = useStyles({ span })
  return (
    <div className={cn(classes.container, 'clearfix')}>
      <ResponsiveImage {...image} />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    gridColumn: ({ span }) => `span ${span || 1}`
  }
}, { name: 'ImageSlice' })

export default ImageSlice

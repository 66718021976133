import { createAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { routeContent } from '../actions'
import { getEpisodes } from './content/selectors'
import { navigateHome } from './location'

export const toggleMenu = createAction('episodes/toggleMenu')
export const toggleWatchNowDropdown = createAction('episodes/toggleWatchNowDropdown')

const episodesSlice = createSlice({
  name: 'episodes',
  initialState: {
    currentIndex: 0,
    playing: false,
    muted: false,
    menuOpen: false,
    selectEpisodeInMenu: null,
    watchNowDropdown: {
      open: false,
      position: null
    }
  },
  reducers: {
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload
      state.watchNowDropdown.open = false
    },
    playEpisode: (state, action) => {
      state.playing = true
    },
    pauseEpisode: (state, action) => {
      state.playing = false
    },
    setMuted: (state, action) => {
      state.muted = action.payload
    },
    openMenu: (state, action) => {
      state.menuOpen = true
    },
    closeMenu: (state, action) => {
      state.menuOpen = false
    },
    selectEpisodeInMenu: (state, action) => {
      state.selectEpisodeInMenu = action.payload
    },
    navigateToEpisode: (state, action) => {
      // state.menuOpen = false
      // state.menuTransitionedIn = false
    },
    openWatchNowDropdown: (state, action) => {
      state.watchNowDropdown.open = true
      state.watchNowDropdown.position = action.payload
    },
    closeWatchNowDropdown: (state, action) => {
      state.watchNowDropdown.open = false
    }
  },
  extraReducers: {
    [routeContent]: (state, action) => {
      state.menuOpen = false
    },
    [navigateHome]: (state, action) => {
      state.menuOpen = false
    },
    'stage/endAct': (state, action) => {
      if (action.payload === 'actMenuToEpisode') {
        state.menuOpen = false
        state.selectEpisodeInMenu = null
      }
    }
  }
})

export const {
  setCurrentIndex,
  playEpisode,
  pauseEpisode,
  setMuted,
  openMenu,
  closeMenu,
  selectEpisodeInMenu,
  navigateToEpisode,
  openWatchNowDropdown,
  closeWatchNowDropdown
} = episodesSlice.actions

export const getCurrentEpisodeIndex = state => state.episodes.currentIndex
export const getCurrentEpisode = state => {
  return getEpisodes(state).episodes[getCurrentEpisodeIndex(state)]
}
export const isPlaying = state => state.episodes.playing
export const isMuted = state => state.episodes.muted
export const isMenuOpen = state => state.episodes.menuOpen
export const getSelectedEpisodeInMenu = state => state.episodes.selectEpisodeInMenu
export const getSelectedEpisodeIndexInMenu = createSelector(
  getSelectedEpisodeInMenu,
  getEpisodes,
  (selected, episodes) => selected ? episodes.episodes.indexOf(selected) : -1
)

export const getWatchNowLinks = createSelector(
  getCurrentEpisode,
  (episode) => episode ? episode.watchNowLinks : null
)

export const getWatchNowLabel = createSelector(
  getCurrentEpisode,
  (episode) => episode ? episode.watchNowLabel : null
)

export const isWatchNowDropdownOpen = state => state.episodes.watchNowDropdown.open
export const getWatchNowDropdownPosition = state => state.episodes.watchNowDropdown.position

export default episodesSlice.reducer

import get from 'lodash/get'
import map from 'lodash/map'
import first from 'lodash/first'
import compact from 'lodash/compact'
import defaults from 'lodash/defaults'
import { createSelector } from 'reselect'
import { getCurrentRoutePath, isPreviewRoute } from '../location'
import isEmpty from 'lodash/isEmpty'

export const getState = state => state

export const isContentBusy = state => !!(get(state, ['content', 'page', 'busy']) || get(state, ['content', 'global', 'busy']))
export const getContentError = state => state.content.page.error || state.content.global.error
export const getPageContent = state => get(state, ['content', 'page', 'content'])
export const isContentError404 = state => get(getContentError(state), ['status']) === 404

export const getGlobalContent = state => get(state, ['content', 'global', 'content'])
export const getSocialLinks = state => get(getGlobalContent(state), ['socialLinks'])
export const isContentReadyOrError = state => !isContentBusy(state) && !!(
  (getPageContent(state) && getGlobalContent(state)) || getContentError(state)
)

export const getSiteTitle = state => get(getGlobalContent(state), ['title'])
export const getEpisodes = state => get(getGlobalContent(state), ['episodes'])
export const getPosterImage = state => get(getEpisodes(state), ['posterImage'])

export const getPageId = state => get(getPageContent(state), ['id'])
export const getPageSlug = state => get(getPageContent(state), ['slug'])
export const getPageType = state => get(getPageContent(state), ['type'])
export const getPageTitle = state => get(getPageContent(state), ['title'])
export const isErrorPage = state => getPageType(state) === 'errorPage'

export const isHomepage = createSelector(
  getCurrentRoutePath,
  (path) => path === '/'
)

export const getPageMetaData = createSelector(
  isHomepage,
  getGlobalContent,
  getPageContent,
  getEpisodes,
  getCurrentRoutePath,
  (homepage, global, page, episodes, path) => {
    const defaultCanonicalUrl = `${process.env.REACT_APP_CANONICAL_URL_BASE}${path}`
    return defaults(
      {},
      homepage ? get(episodes, ['seo'], {}) : {
        ...get(episodes, ['seo'], {}),
        ...get(page, ['seo'], {})
      },
      {
        // these are the defaults for fields that aren't set above
        metaTitle: get(page, ['title']),
        siteName: get(global, ['title']),
        ogUrl: defaultCanonicalUrl,
        canonicalUrl: defaultCanonicalUrl
      }
    )
  }
)

export const getPageHeadTitle = createSelector(
  getGlobalContent,
  getPageMetaData,
  getPageTitle,
  isHomepage,
  (global, meta, title = get(meta, ['title']), homepage) => {
    const globalTitle = get(global, ['title'])
    if (homepage || !title) {
      return globalTitle
    }
    if (!globalTitle) {
      return title
    }
    return `${title} – ${globalTitle}`
  }
)

const getSlices = (page) => {
  return (page && page.slices) ? map(page.slices, (slice, index) => ({
    id: `${page.id}-${index}`,
    ...slice
  })) : undefined
}

export const getPageSlices = createSelector(
  getPageContent,
  getSlices
)

export const getPreviewMode = state => get(state, ['content', 'preview'])

export const isPreviewMode = createSelector(
  isPreviewRoute,
  getPreviewMode,
  (previewRoute, previewMode) => previewRoute || previewMode
)

export const getMenuItems = createSelector(
  getGlobalContent,
  getCurrentRoutePath,
  (global, path) => {
    const menu = get(global, ['menu'])
    if (!menu) return []
    var pathSplit = compact(path.split('/'))
    return menu.map(item => ({
      ...item,
      selected: get(item, ['reference', 'slug']) === first(pathSplit)
    }))
  }
)

export const isDialogOpen = createSelector(
  isHomepage,
  getPageContent,
  (homepage, page) => !homepage && page && !isEmpty(page)
)

import React, { useMemo, useRef, useCallback, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { resolveLink } from '../helpers/resolveLink'
import Link from './Link'
import gsap from 'gsap'
import get from 'lodash/get'
import detectIt from 'detect-it'

const AnimatedLink = forwardRef(({ className, link, text, onClick, ...rest }, ref) => {
  const classes = useStyles()
  const copyRef = useRef()
  const textRef = useRef()
  const resolvedLink = useMemo(() => link ? resolveLink(link) : null, [link])
  const linkText = text || resolvedLink.text || get(link, [0, 'title'], link.title)

  const animate = () => {
    if (textRef.current) {
      gsap.set(textRef.current, { y: '100%', opacity: 0 })
      gsap.set(copyRef.current, { y: '0%', opacity: 1 })
      gsap.to(textRef.current, { y: '0%', opacity: 1, duration: 0.3, ease: 'sine.out' })
      gsap.to(copyRef.current, { y: '-100%', opacity: 0, duration: 0.3, ease: 'sine.out' })
    }
  }

  const onMouseEnter = useCallback(() => {
    animate()
  }, [])

  const handleClick = useCallback((e) => {
    if (detectIt.primaryInput === 'touch') { animate() }
    if (onClick) { onClick(e) }
  }, [onMouseEnter, onClick])

  return (
    <Link
      nonLinkTag='div'
      className={cn(classes.link, className)}
      link={resolvedLink}
      onMouseEnter={detectIt.primaryInput === 'touch' ? null : onMouseEnter}
      onClick={detectIt.primaryInput !== 'touch' ? handleClick : null}
      onMouseUp={detectIt.primaryInput === 'touch' ? handleClick : null}
      ref={ref}
      {...rest}
    >
      <div className={classes.inner}>
        <span className={classes.copy} ref={copyRef}>{linkText}</span>
        <span className={classes.text} ref={textRef}>{linkText}</span>
      </div>
    </Link>
  )
})

const useStyles = createUseStyles({
  link: {
    padding: 0,
    fontSize: 14,
    backgroundColor: 'transparent',
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    color: 'currentColor',
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    position: 'relative',
    overflow: 'hidden'
  },
  inner: {
    overflow: 'hidden'
  },
  text: {
    whiteSpace: 'nowrap',
    display: 'block',
    transform: 'translate(0, 0%)'
  },
  copy: {
    whiteSpace: 'nowrap',
    display: 'block',
    position: 'absolute',
    transform: 'translate(0, -100%)'
  }
})

export default AnimatedLink

import * as fonts from './fonts'
import * as breakpoints from './breakpoints'

export const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
}

export const baseBodyStyles = {
  fontFamily: fonts.body,
  fontWeight: fonts.bodyFontWeight,
  fontSize: 16,
  lineHeight: 1.25
  // fontDisplay: 'swap'
}

export const headings = {
  h1: {
    lineHeight: 0.85,
    fontSize: 72,
    fontWeight: fontWeight.regular,
    textTransform: 'uppercase',
    marginBottom: '0.5em',
    letterSpacing: '-0.04em',
    [breakpoints.up('md')]: {
      fontSize: 180
    }
  },
  h2: {
    fontSize: 64,
    fontWeight: fontWeight.regular,
    lineHeight: 0.85,
    textTransform: 'uppercase',
    marginBottom: '0.5em',
    letterSpacing: '-0.04em',
    [breakpoints.up('md')]: {
      fontSize: 140
    }
  },
  h3: {
    textTransform: 'uppercase',
    marginBottom: '0.5em',
    letterSpacing: '-0.04em',
    lineHeight: 0.85,
    fontWeight: fontWeight.regular,
    fontSize: 50
  },
  h4: {
    textTransform: 'uppercase',
    marginBottom: '0.5em',
    letterSpacing: '-0.04em',
    lineHeight: 0.85,
    fontWeight: fontWeight.regular,
    fontSize: 30
  },
  h5: {
    textTransform: 'uppercase',
    marginBottom: '0.5em',
    letterSpacing: '-0.04em',
    lineHeight: 0.85,
    fontWeight: fontWeight.regular,
    fontSize: 22
  },
  h6: {
    textTransform: 'uppercase',
    marginBottom: '0.5em',
    letterSpacing: '-0.04em',
    lineHeight: 0.85,
    fontWeight: fontWeight.regular,
    fontSize: 18
  }
}

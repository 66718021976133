import React from 'react'
import { JssProvider } from 'react-jss'

const id = {
  minify: process.env.NODE_ENV === 'production'
}

export default function ConfiguredJssProvider (props) {
  return <JssProvider id={id} {...props} />
}

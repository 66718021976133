import { ShaderMaterial } from 'three'
import { extend } from 'react-three-fiber'

class VideoTransitionMaterial extends ShaderMaterial {
  constructor () {
    super({
      uniforms: {
        texture1: { type: 't', value: null },
        texture2: { type: 't', value: null },
        displacementTexture: { type: 't', value: null },
        time: { type: 'f', value: 0 },
        textureIndex: { type: 'f', value: 0 },
        greyscaleFactor: { type: 'f', value: 1 },
        blendColor: { value: [0.004, 0.004, 0.004, 1] },
        blendFactor: { type: 'f', value: 0 },

        distortFactor: { type: 'f', value: 0 },
        shiftStrength: { value: 0 },

        offsetY: { type: 'f', value: 0 },
        blurSensibility: { value: [0.1, 0.25] },
        blurStrength: { value: 0 }
      },
      vertexShader:
      `varying vec2 vUv;
      void main() {
        vec3 pos = position;
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.);
      }`,
      fragmentShader:
      `uniform sampler2D texture1;
      uniform sampler2D texture2;
      uniform sampler2D displacementTexture;
      uniform float textureIndex;
      uniform float time;
      uniform float distortFactor;
      uniform float greyscaleFactor;
      uniform float shiftStrength;
      uniform float blendFactor;
      uniform float offsetY;
      uniform vec4 blendColor;
      uniform vec2 blurSensibility;
      uniform float blurStrength;

      varying vec2 vUv;

      vec4 blur (sampler2D t, vec2 uv_0, float blurStrength) {
        vec4 sum = vec4(0.0);
        sum += texture2D(t, uv_0 - vec2(4.0) * blurStrength) * 0.051;
        sum += texture2D(t, uv_0 - vec2(3.0) * blurStrength) * 0.0918;
        sum += texture2D(t, uv_0 - vec2(2.0) * blurStrength) * 0.12245;
        sum += texture2D(t, uv_0 - blurStrength) * 0.1531;
        sum += texture2D(t, uv_0) * 0.1633;
        sum += texture2D(t, uv_0 + blurStrength) * 0.1531;
        sum += texture2D(t, uv_0 + vec2(2.0) * blurStrength) * 0.12245;
        sum += texture2D(t, uv_0 + vec2(3.0) * blurStrength) * 0.0918;
        sum += texture2D(t, uv_0 + vec2(4.0) * blurStrength) * 0.051;
        return sum;
      }

      float grayscale (vec3 c) {
        return dot(c, vec3(0.299, 0.587, 0.114));
      }    

      vec4 greyscale(vec4 color, float factor) {
        float grey = 0.21 * color.r + 0.71 * color.g + 0.07 * color.b;
        return vec4(color.rgb * (1.0 - factor) + (grey * factor), 1.0);
      }

      vec4 rgbShift(sampler2D t, vec2 uv, float dist, float grey) {
        vec4 red = greyscale(texture2D(t, vec2(uv.x, uv.y + dist * 0.1)), grey);
        vec4 green = greyscale(texture2D(t, uv), grey);
        vec4 blue = greyscale(texture2D(t, vec2(uv.x, uv.y + dist * -0.1)), grey);
        return vec4(red.r, green.g, blue.b, green.a);
      }

      void main() {
        vec2 position = vUv;
        vec4 displacementMap = texture2D(displacementTexture, vUv);
        float displacementData = grayscale(displacementMap.rgb);
        // vec2 displacement = displacementData * -(displacementMap.gb - vec2(displacementData * 0.5)) * distortFactor;
        vec2 displacement = displacementData * vec2(0, distortFactor);
        // vec2 displacement = displacementData * vec2(distortFactor, distortFactor);

        vec2 uv = vUv + displacement + vec2(0.0, offsetY);

        // vec4 color1 = blur(texture1, uv, smoothstep(blurSensibility.x, blurSensibility.y, displacementData) * blurStrength);
        // vec4 color2 = blur(texture2, uv, smoothstep(blurSensibility.x, blurSensibility.y, displacementData) * blurStrength);

        // apply a simple rgb shift based on that effect
        vec4 color1 = rgbShift(texture1, uv, shiftStrength, greyscaleFactor);
        vec4 color2 = rgbShift(texture2, uv, shiftStrength, greyscaleFactor);

        gl_FragColor = mix(color1, color2, textureIndex);
        // gl_FragColor = greyscale(gl_FragColor, greyscaleFactor);
        gl_FragColor = mix(gl_FragColor, blendColor, blendFactor);
      }`
    })
  }

  set map (value) {
    this.uniforms.texture1.value = value
  }

  get map () {
    return this.uniforms.texture1.value
  }

  set map2 (value) {
    this.uniforms.texture2.value = value
  }

  get map2 () {
    return this.uniforms.texture2.value
  }

  set displacementTexture (value) {
    this.uniforms.displacementTexture.value = value
  }

  get displacementTexture () {
    return this.uniforms.displacementTexture.value
  }

  set textureIndex (value) {
    this.uniforms.textureIndex.value = value
  }

  get textureIndex () {
    return this.uniforms.textureIndex.value
  }

  set time (value) {
    this.uniforms.time.value = value
  }

  get time () {
    return this.uniforms.time.value
  }

  set greyscaleFactor (value) {
    this.uniforms.greyscaleFactor.value = value
  }

  get greyscaleFactor () {
    return this.uniforms.greyscaleFactor.value
  }

  set blendFactor (value) {
    this.uniforms.blendFactor.value = value
  }

  get blendFactor () {
    return this.uniforms.blendFactor.value
  }

  set blendColor (value) {
    this.uniforms.blendColor.value = value
  }

  get blendColor () {
    return this.uniforms.blendColor.value
  }

  set offsetY (value) {
    this.uniforms.offsetY.value = value
  }

  get offsetY () {
    return this.uniforms.offsetY.value
  }

  set blurSensibility (value) {
    this.uniforms.blurSensibility.value = value
  }

  get blurSensibility () {
    return this.uniforms.blurSensibility.value
  }

  set blurStrength (value) {
    this.uniforms.blurStrength.value = value
  }

  get blurStrength () {
    return this.uniforms.blurStrength.value
  }

  set distortFactor (value) {
    this.uniforms.distortFactor.value = value
  }

  get distortFactor () {
    return this.uniforms.distortFactor.value
  }

  set shiftStrength (value) {
    this.uniforms.shiftStrength.value = value
  }

  get shiftStrength () {
    return this.uniforms.shiftStrength.value
  }
}

extend({ VideoTransitionMaterial })

import { createSlice } from '@reduxjs/toolkit'
import theme from '../../style/theme'

const cursorSlice = createSlice({
  name: 'cursor',
  initialState: {
    loading: false,
    hovering: false,
    text: '',
    backgroundColor: theme.colors.white,
    visible: true
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setHovering: (state, action) => {
      state.hovering = action.payload.hovering
      state.text = action.payload.text
      state.backgroundColor = action.payload.backgroundColor
    },
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload
    },
    hide: (state, action) => {
      state.visible = false
    },
    show: (state, action) => {
      state.visible = true
    }
  },
  extraReducers: {
    'route/content': (state, action) => {
      if (action.payload[0] === '/') {
        state.backgroundColor = theme.colors.white
      }
    },
    'page/content_FULFILLED': (state, action) => {
      state.backgroundColor = theme.colors.black
    }
  }
})

export const {
  setLoading,
  setHovering,
  setBackgroundColor,
  hide,
  show
} = cursorSlice.actions

export const isLoading = state => state.cursor.loading
export const getText = state => state.cursor.text
export const getColor = state => state.cursor.color
export const isHovering = state => state.cursor.hovering
export const isVisible = state => state.cursor.visible
export const getBackgroundColor = state => state.cursor.backgroundColor

export default cursorSlice.reducer

import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'

const Divider = () => {
  const classes = useStyles()
  return (
    <div className={classes.divider} />
  )
}

const useStyles = createUseStyles({
  divider: {
    marginTop: theme.spacing(-2),
    marginBottom: [theme.spacing(2), '!important'],
    gridColumn: 'span 3',
    height: 1,
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('sm')]: {
      marginBottom: [0, '!important'],
      margin: 0
    }
  }
}, { name: 'Divider' })

export default Divider

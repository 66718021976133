import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'
import theme from '../style/theme'

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
    ),
    internal_link: ({ mark, children }) => (
      <Link link={resolveLink(mark)}>{children}</Link>
    ),
    caption: ({ mark, children }) => {
      const classes = useStyles()
      return <span className={classes.caption}>{children}</span>
    }
  },
  types: {
    quote: ({ node: { quote, caption } }) => {
      const classes = useStyles()
      return (
        <>
          <blockquote>{quote}</blockquote>
          {caption && <span className={classes.quoteCaption}>{caption}</span>}
        </>
      )
    }
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, children }, ref) => {
  const classes = useStyles()
  const Component = tag
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <Component className={cn(className, classes.container)} ref={ref}>
      <BlockContent blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

const useStyles = createUseStyles({
  container: {
    '& blockquote': {
      fontSize: 20,
      margin: [0, 0, theme.spacing(1), 0],
      [theme.breakpoints.up('sm')]: {
        margin: [0, 0, theme.spacing(2), theme.spacing(2)],
        fontSize: 24
      }
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  caption: {
    textTransform: 'uppercase',
    fontSize: 11,
    display: 'block',
    letterSpacing: '0.03em'
  },
  quoteCaption: {
    textTransform: 'uppercase',
    fontSize: 11,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2)
    }
  }
}, { name: 'RichText' })

export default RichText

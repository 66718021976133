
import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'

export const getVideoAspect = (video) => {
  if (video.aspect) return video.aspect // vimeo source
  const aspect = get(video, ['data', 'aspect_ratio'], '16:9').split(':') // mux source
  return aspect[1] / aspect[0]
}

export const getVideoAspectFromVimeo = (video) => {
  const aspect = get(video, ['data', 'aspect_ratio'], '16:9').split(':')
  return aspect[1] / aspect[0]
}

export const getHlsSourceFromVimeo = (vimeoVideo) => {
  return find(get(vimeoVideo, ['sizes'], []), source => source.quality === 'hls')
}

export const getMp4SourcesFromVimeo = (vimeoVideo) => {
  return filter(get(vimeoVideo, ['sizes'], []), source => source.quality !== 'hls')
}

export const getCoverVideoSize = (width, height, video) => {
  const windowAspect = height / width
  const videoAspect = getVideoAspect(video)
  if (windowAspect < videoAspect) {
    return { x: width, y: width * videoAspect }
  } else {
    return { x: height * (1 / videoAspect), y: height }
  }
}

export const getContainVideoSize = (width, height, video) => {
  const windowAspect = height / width
  const videoAspect = getVideoAspect(video)
  if (windowAspect > videoAspect) {
    return { x: width, y: width * videoAspect }
  } else {
    return { x: height * (1 / videoAspect), y: height }
  }
}

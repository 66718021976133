import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import gsap from 'gsap'

import Meta from './Meta'
import PageLoadingBar from './PageLoadingBar'
import Page from './Page'
import Cursor from './Cursor'
import { useDispatch } from 'react-redux'
import { siteLoaded } from '../redux/slices/layout'

function App () {
  const classes = useStyles() /* eslint-disable-line */
  const dispatch = useDispatch()

  gsap.config({ force3D: true })

  useEffect(() => {
    dispatch(siteLoaded())
  }, [])

  return (
    <>
      <Meta />
      <PageLoadingBar />
      <Cursor>
        <Page />
      </Cursor>
    </>
  )
}

const useStyles = createUseStyles({
  '@global': {
    ...theme.global
  }
}, { name: 'App' })

export default App

import React from 'react'
import { createUseStyles } from 'react-jss'
import Header from './Header'
import Episodes from './Episodes'
import VideoPlayer from './VideoPlayer'
import FullVideoPlayer from './VideoPlayer/FullVideoPlayer'
import Menu from './Menu'
import Dialog from './Dialog'
import WatchNowDropdown from './WatchNowDropdown'
import PreviewVideoSources from './VideoPlayer/PreviewVideoSources'

const Page = () => {
  const classes = useStyles()

  return (
    <>
      <Header />
      <PreviewVideoSources />
      <VideoPlayer />
      <FullVideoPlayer />
      <main className={classes.main}>
        <Episodes />
      </main>
      <Menu />
      <Dialog />
      <WatchNowDropdown />
    </>
  )
}

const useStyles = createUseStyles({
  main: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  }
}, { name: 'Page' })

export default Page

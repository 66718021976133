import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import theme from '../style/theme'
import { isDialogOpen, getPageContent, getPageSlices } from '../redux/slices/content'
import ErrorBoundary from './ErrorBoundary'
import RichTextSlice from './Slices/RichTextSlice'
import ImageSlice from './Slices/ImageSlice'
import useDialogTransition from './useDialogTransition'
import TitleSlice from './Slices/TitleSlice'

const sliceComponentSelector = {
  richText: RichTextSlice,
  image_slice: ImageSlice,
  titleSlice: TitleSlice
}

function Dialog () {
  const classes = useStyles()
  const open = useSelector(isDialogOpen)
  const slices = useSelector(getPageSlices)
  const page = useSelector(getPageContent)

  const [outerRef, innerRef] = useDialogTransition(open)

  const sliceComponents = useMemo(() => {
    if (!slices) return null
    return (
      slices.map((slice, index) => {
        const Component = sliceComponentSelector[slice.type]
        return (
          <ErrorBoundary key={`slice-${index}`}>
            <Component slice={slice} page={page} />
          </ErrorBoundary>
        )
      })
    )
  }, [slices])

  return (
    <section ref={outerRef} className={cn(classes.dialog, { open })}>
      {page && page.id && (
        <div className={classes.inner} key={page.id} ref={innerRef}>
          <div className={classes.grid}>
            {sliceComponents}
          </div>
        </div>
      )}
    </section>
  )
}

const useStyles = createUseStyles({
  dialog: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    pointerEvents: 'none',
    zIndex: theme.zIndex.dialog,
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    transform: 'translate(0, -100%)',
    overflow: 'hidden'
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    transform: 'translate(0, 100%)'
  },
  grid: {
    margin: [theme.spacing(8), theme.spacing(1), theme.spacing(4)],
    [theme.breakpoints.up('sm')]: {
      margin: [theme.spacing(12), theme.spacing(1), theme.spacing(8)],
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.spacing(4)
    },
    [theme.breakpoints.up('xl')]: {
      gridGap: theme.spacing(6),
      margin: [theme.spacing(12), theme.spacing(1), theme.spacing(8)]
    },
    '& > *': {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0
      }
    }
  }
}, { name: 'Dialog' })

export default Dialog

import { useEffect } from 'react'
import inDOM from 'dom-helpers/canUseDOM'
import { rehydrated, routeContent } from '../actions'
import { getEpisodes, isHomepage } from '../slices/content'
import {
  closeMenu,
  isMenuOpen,
  navigateToEpisode,
  openMenu,
  pauseEpisode,
  playEpisode,
  setCurrentIndex
} from '../slices/episodes'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'
import gsap from 'gsap'
import { navigateHome } from '../slices/location'
import { navigate } from '../../helpers/route'
import { endAct, isAnyActRunning, startAct } from '../slices/stage'

const PAGE_TRANSITION_OUT_DURATION = 0.25
const PAGE_TRANSITION_IN_DURATION = 1

export const scrollState = {
  pages: 0,
  previousPage: 0,
  page: 0,
  enabled: false,
  pointerEventsEnabled: false,
  speed: 0,
  callbacks: [],
  direction: null,
  playingFullLengthVideo: false,

  panning: false,
  position: 0, // -0.8,
  target: 0,
  pageScrollListeners: []
}

export const usePageScrollListener = (callback) => {
  useEffect(() => {
    scrollState.pageScrollListeners.push(callback)
    return () => {
      remove(scrollState.pageScrollListeners, x => x === callback)
    }
  }, [callback])
}

export const usePageIndexChangedCallback = (callback) => {
  useEffect(() => {
    scrollState.callbacks.push(callback)
    return () => {
      remove(scrollState.callbacks, x => x === callback)
    }
  }, [callback])
}

const pageTimeline = gsap.timeline()

const goToPage = (page, dispatch) => {
  scrollState.previousPage = scrollState.page
  scrollState.page = page
  scrollState.enabled = false
  scrollState.pointerEventsEnabled = false
  scrollState.speed = 0
  pageTimeline.kill()
  const target = page - ((page - scrollState.previousPage) / 2)
  pageTimeline.set(scrollState, { position: scrollState.position })
  pageTimeline.to(scrollState,
    {
      position: target,
      duration: PAGE_TRANSITION_OUT_DURATION,
      ease: 'none',
      onComplete: () => {
        dispatch(setCurrentIndex(scrollState.page))
        forEach(scrollState.callbacks, cb => cb(scrollState))
      }
    })
  pageTimeline.to(scrollState,
    {
      position: page,
      duration: PAGE_TRANSITION_IN_DURATION,
      ease: 'power4.out',
      onComplete: () => {
        scrollState.panCurrentPosition = scrollState.position
        scrollState.enabled = true
        if (!scrollState.playingFullLengthVideo) {
          scrollState.pointerEventsEnabled = true
        }
      }
    })
}

const nextPageTrigger = (dispatch) => {
  if (scrollState.page !== (scrollState.pages - 1)) {
    goToPage(scrollState.page + 1, dispatch)
  }
}

const previousPageTrigger = (dispatch) => {
  if (scrollState.page !== 0) {
    goToPage(scrollState.page - 1, dispatch)
  }
}

const createScroller = () => {
  const loop = () => {
    forEach(scrollState.pageScrollListeners, cb => cb(scrollState))
    window.requestAnimationFrame(loop)
  }
  window.requestAnimationFrame(loop)
  return document.body
}

const attachTouchEvents = (element, dispatch, usePanGestures) => {
  import('hammerjs').then(Hammer => {
    var hammer = Hammer.default(document.body, {})
    hammer.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 2 })
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 40 })

    hammer.on('panstart', (e) => {
      if (scrollState.pointerEventsEnabled) {
        scrollState.panning = true
        scrollState.panDelta = e.deltaY
        scrollState.panCurrentPosition = scrollState.position
      }
    })
    hammer.on('panend', (e) => { scrollState.panning = false })
    hammer.on('panmove', (e) => {
      if (scrollState.pointerEventsEnabled && scrollState.panning) {
        const delta = (scrollState.panDelta - e.deltaY)
        scrollState.direction = delta < 0 ? 'up' : 'down'
        scrollState.position = scrollState.panCurrentPosition + ((delta) / 900)
        if (delta > 300) {
          scrollState.panning = false
          scrollState.direction = 'down'
          nextPageTrigger(dispatch)
        }
        if (delta < -300) {
          scrollState.panning = false
          scrollState.direction = 'up'
          previousPageTrigger(dispatch)
        }
      }
    })
    hammer.on('swipeup', (e) => {
      if (scrollState.pointerEventsEnabled) {
        scrollState.panning = false
        scrollState.direction = 'down'
        nextPageTrigger(dispatch)
      }
    })
    hammer.on('swipedown', (e) => {
      if (scrollState.pointerEventsEnabled) {
        scrollState.panning = false
        scrollState.direction = 'up'
        previousPageTrigger(dispatch)
      }
    })
  })
}

const attachWheelEvents = (element, dispatch) => {
  const onWheel = (e) => {
    if (scrollState.pointerEventsEnabled) {
      scrollState.speed += e.deltaY * 0.00015
      if ((scrollState.page === 0 && e.deltaY < 0) ||
        (scrollState.page === scrollState.pages - 1 && e.deltaY > 0)) {
        scrollState.speed *= 0.5
      }
      scrollState.direction = scrollState.speed > 0 ? 'down' : 'up'
    }
  }
  window.addEventListener('wheel', onWheel)
}

const attachePageScrollTracker = (dispatch) => {
  const loop = () => {
    if (scrollState.enabled) {
      const overSpeedThreshold = Math.abs(scrollState.speed) > 0.05
      // If we go over the scroll speed threshold then move to the next page
      if (overSpeedThreshold) {
        const goToPageTrigger = scrollState.speed > 0 ? nextPageTrigger : previousPageTrigger
        goToPageTrigger(dispatch)
      }
      // If we are not panning then move to the closest position
      if (!scrollState.panning) {
        scrollState.position += scrollState.speed
        scrollState.target = Math.round(scrollState.position)
        scrollState.target = Math.max(Math.min(scrollState.target, scrollState.pages - 1), 0)
        if (!overSpeedThreshold && scrollState.target !== scrollState.page) {
          const goToPageTrigger = scrollState.speed > 0 ? nextPageTrigger : previousPageTrigger
          goToPageTrigger(dispatch)
        } else {
          const diff = scrollState.target - scrollState.position
          scrollState.position += ((Math.sign(diff) * Math.pow(Math.abs(diff), 0.7)) * 0.05)
        }
      }
    }

    scrollState.speed *= 0.9

    window.requestAnimationFrame(loop)
  }
  window.requestAnimationFrame(loop)
}

const navigateDirectlyTo = (dispatch, page) => {
  var movingUp = page < scrollState.page
  scrollState.previousPage = scrollState.page
  scrollState.page = page
  scrollState.position = page + (0.49 * (movingUp ? 1 : -1))
  scrollState.speed = 0
  scrollState.enabled = true
  scrollState.pointerEventsEnabled = true
  dispatch(setCurrentIndex(scrollState.page))
  forEach(scrollState.callbacks, cb => cb(scrollState))
}

export default store => next => action => {
  if (!inDOM) return next(action)

  var result = next(action)

  const episodes = getEpisodes(store.getState()).episodes
  scrollState.pages = episodes.length

  switch (action.type) {
    case rehydrated.toString():
      var element = createScroller()
      attachTouchEvents(element, store.dispatch, true)
      attachWheelEvents(element, store.dispatch)
      attachePageScrollTracker(store.dispatch)
      if (!isHomepage(store.getState())) {
        scrollState.enabled = false
        scrollState.pointerEventsEnabled = false
      }
      break
    case playEpisode.toString():
      scrollState.playingFullLengthVideo = true
      scrollState.pointerEventsEnabled = false
      break
    case pauseEpisode.toString():
      scrollState.playingFullLengthVideo = false
      scrollState.pointerEventsEnabled = true
      break
    case openMenu.toString():
      scrollState.pointerEventsEnabled = false
      break
    case closeMenu.toString():
      break
    case startAct.toString():
      scrollState.pointerEventsEnabled = false
      break
    case endAct.toString():
      if (!isAnyActRunning(store.getState()) && !isMenuOpen(store.getState()) && isHomepage(store.getState())) {
        scrollState.enabled = true
        scrollState.pointerEventsEnabled = true
      }
      break
    case navigateToEpisode.toString():
      var page = episodes.indexOf(action.payload)
      navigateDirectlyTo(store.dispatch, page)
      break
    case routeContent.toString(): {
      scrollState.enabled = isHomepage(store.getState())
      scrollState.pointerEventsEnabled = scrollState.enabled
      break
    }
    case navigateHome.toString(): {
      if (scrollState.page !== 0) {
        navigateDirectlyTo(store.dispatch, 0)
      }
      if (!isHomepage(store.getState())) {
        navigate(store.dispatch, store.getState, '/')
      }
      break
    }
  }

  return result
}

import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'Neue Haas Unica Pro'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 400

export const preload = [
  require('./neue_haas_unica_pro.woff2')
].filter(item => !item.startsWith('data:'))

export const faces = [
  {
    fontFamily: bodyFace,
    src: oneLineTrim`
      url('${require('./neue_haas_unica_pro.woff2')}') format('woff2'),
      url('${require('./neue_haas_unica_pro.woff')}') format('woff')`,
    fontWeight: bodyFontWeight,
    fontStyle: 'normal'
  }
]

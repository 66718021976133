import { useEffect, useRef } from 'react'
import detectIt from 'detect-it'
import gsap from 'gsap'
import { useSelector } from 'react-redux'
import { isPlaying } from '../redux/slices/episodes'

export default (up = true) => {
  const ref = useRef()
  const playing = useSelector(isPlaying)
  useEffect(() => {
    if (detectIt.primaryInput !== 'touch') {
      gsap.to(ref.current, { autoAlpha: playing ? 0 : 1, y: playing ? (up ? '-100%' : '100%') : 0, duration: 0.5, ease: 'sine.out' })
    }
  }, [playing])
  return ref
}

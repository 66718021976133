import React, { useRef, forwardRef, useMemo, Suspense, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import { Canvas, useThree, useLoader } from 'react-three-fiber'
import { VideoTexture, TextureLoader, LinearFilter, MirroredRepeatWrapping } from 'three'
import { getContainVideoSize, getCoverVideoSize } from '../../helpers/video'
import gsap from 'gsap'
import composeRefs from '../../helpers/composeRefs'
import omit from 'lodash/omit'

import './VideoTransitionMaterial'

import theme from '../../style/theme'
import { ReactReduxContext, useSelector } from 'react-redux'
import { isPlaying } from '../../redux/slices/episodes'
import forwardContext from '../../helpers/forwardContext'
import detectIt from 'detect-it'
import { usePageScrollListener } from '../../redux/middlewares/scrollPager'
import { stage } from '../../redux/middlewares/stage'
import { isFullPlayerReady } from '../../redux/slices/layout'

const WrappedCanvas = forwardContext(ReactReduxContext)(Canvas)

const Plane = forwardRef(({ color = 'red', displacementTexture, materialRef, ...props }, ref) => {
  useEffect(() => {
    const tick = (time) => {
      if (materialRef.current) {
        materialRef.current.time = time * 2
      }
    }
    gsap.ticker.add(tick)
    return () => {
      gsap.ticker.remove(tick)
    }
  }, [])

  return (
    <mesh {...props} ref={ref}>
      <planeBufferGeometry attach='geometry' args={[1, 1, 32, 32]} />
      <videoTransitionMaterial ref={materialRef} attach='material' displacementTexture={displacementTexture} textureIndex={0} />
    </mesh>
  )
})

const Videos = ({ elementRef, episodes }) => {
  const meshRef = useRef()
  const materialRef = useRef()
  const { size, camera } = useThree()
  const playingFullLengthVideo = useSelector(isPlaying)
  const localsRef = useRef({ scrollAnimationEnabled: true })
  const fullVideoPlayerReady = useSelector(isFullPlayerReady)

  const width = size.width / camera.zoom
  const height = size.height / camera.zoom

  // We use a ref here because we do not what to trigger the width/height effect
  localsRef.current.playingFullLengthVideo = playingFullLengthVideo
  localsRef.current.fullLengthVideoTextures = null
  localsRef.current.width = width
  localsRef.current.height = height

  const previewVideoTextures = useMemo(() => {
    const videosContainer = document.getElementById('videos-sources')
    return map(videosContainer.children, (video) => new VideoTexture(video))
  }, [])

  const textures = useLoader(TextureLoader, ['./clouds.jpg'])
  const [displacementTexture] = textures.map(texture => {
    texture.minFilter = LinearFilter
    texture.wrapS = MirroredRepeatWrapping
    texture.wrapT = MirroredRepeatWrapping
    return texture
  })

  useEffect(() => {
    stage.videoMeshRef = meshRef
    stage.video = episodes[0].video
  }, [])

  // This is the effect to size up the video inside the canvas
  useEffect(() => {
    const timeline = gsap.timeline()
    const size = localsRef.current.playingFullLengthVideo
      ? getContainVideoSize(width, height, episodes[0].video)
      : getCoverVideoSize(width, height, episodes[0].backgroundVideo)
    timeline.set(meshRef.current.scale, { ...size })
    return () => {
      timeline.kill()
    }
  }, [width, height])

  // This is the main page scroll animation effect
  const timeline = useRef()
  usePageScrollListener(useCallback((scrollState) => {
    if (!timeline.current) {
      timeline.current = gsap.timeline()
      timeline.current.to(materialRef.current, {
        distortFactor: -0.04,
        shiftStrength: 0.2,
        offsetY: 0,
        duration: 1,
        ease: 'sine.out',
        modifiers: {
          distortFactor: (value) => value * (scrollState.direction === 'down' ? -1 : 1),
          shiftStrength: (value) => value * (scrollState.direction === 'down' ? 1 : -1)
        }
      })
      timeline.current.to(materialRef.current, {
        textureIndex: 0.5,
        duration: 1,
        ease: 'sine.out'
      }, 0)
      timeline.current.pause()
    }

    if (materialRef.current) {
      if (localsRef.current.scrollAnimationEnabled) {
        const position = Math.min(Math.max(-0.49, scrollState.position), scrollState.pages - 0.49)
        var textureIndex = Math.min(scrollState.pages - 1, Math.max(0, Math.round(Math.abs(position))))
        const diff = scrollState.position - Math.round(position)

        if (position < 0 || position > (scrollState.pages - 1)) {
          materialRef.current.map = previewVideoTextures[textureIndex]
          materialRef.current.map2 = previewVideoTextures[textureIndex]
        } else {
          materialRef.current.map = previewVideoTextures[textureIndex]
          materialRef.current.map2 = previewVideoTextures[textureIndex + (diff < 0 ? -1 : 1)]
        }
        timeline.current.seek(timeline.current.totalDuration() * (Math.abs(diff) * 2))
      }
    }
  }, [previewVideoTextures]))

  // We need to recreate the texture after we change the url as we sometimes get a blank screen if we don't.
  // For some reason if the video has not loaded it will not created the texture properly or the texture loop will fail
  useEffect(() => {
    if (detectIt.primaryInput !== 'touch') {
      if (fullVideoPlayerReady && playingFullLengthVideo) {
        if (localsRef.current.fullLengthVideoTextures) {
          localsRef.current.fullLengthVideoTextures.dispose()
        }
        localsRef.current.fullLengthVideoTextures = new VideoTexture(document.getElementById('full-video-player'))
        materialRef.current.map2 = localsRef.current.fullLengthVideoTextures
      }
    }
  }, [fullVideoPlayerReady, playingFullLengthVideo])

  // This is the full video transition
  useEffect(() => {
    if (detectIt.primaryInput !== 'touch') {
      if (playingFullLengthVideo) {
        materialRef.current.map2 = localsRef.current.fullLengthVideoTextures
        localsRef.current.scrollAnimationEnabled = false
      }
      const tl = gsap.timeline()
      tl.to(materialRef.current, { greyscaleFactor: playingFullLengthVideo ? 0 : 1, duration: 1 })
      const size = playingFullLengthVideo
        ? getContainVideoSize(width, height, episodes[0].video)
        : getCoverVideoSize(width, height, episodes[0].backgroundVideo)
      tl.to(meshRef.current.scale, { ...size, duration: 1, ease: 'sine.inOut' }, 0)
      const animatedProps = omit({
        textureIndex: playingFullLengthVideo ? 1 : 0,
        blendFactor: playingFullLengthVideo ? 0 : 0.3,
        shiftStrength: 0,
        distortFactor: 0
      }, !playingFullLengthVideo ? ['distortFactor', 'shiftStrength'] : [])
      tl.to(materialRef.current, {
        ...animatedProps,
        duration: 1,
        ease: 'sine.inOut',
        onComplete: () => {
          if (!playingFullLengthVideo) {
            localsRef.current.scrollAnimationEnabled = true
          }
        }
      }, 0)
      return () => {
        tl.kill()
      }
    }
  }, [playingFullLengthVideo])

  return (
    <Plane position={[0, 0, 0]} displacementTexture={displacementTexture} ref={meshRef} materialRef={materialRef} />
  )
}

const VideoContainer = forwardRef(({ className, episodes }, ref) => {
  const classes = useStyles()
  const elementRef = useRef()
  const canvasRef = useRef()

  return (
    <div className={cn(classes.container, className)} ref={composeRefs(ref, elementRef)} id='video-canvas-container'>
      <div className={classes.canvas} ref={canvasRef} id='video-canvas'>
        <WrappedCanvas orthographic camera={{ zoom: 1, position: [0, 0, 1], near: 1, far: 1000 }} resize={{ scroll: false }}>
          <color attach='background' args={[theme.colors.black]} />
          <Suspense fallback={null}>
            <Videos
              elementRef={elementRef}
              episodes={episodes}
            />
          </Suspense>
        </WrappedCanvas>
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'block',
    pointerEvents: 'none',
    width: '100%',
    height: '100vh'
  },
  canvas: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
}, { name: 'VideoContainer' })

export default VideoContainer

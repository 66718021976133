import { useEffect, useState } from 'react'
import getRenderer from '../helpers/deviceDetection/getRenderer'
import detectIt from 'detect-it'

// NOTE: There is a bug on iphone 12 that stops the hls video being rendered into the webgl canvas, see https://bugs.webkit.org/show_bug.cgi?id=218637
// To work around this we detect if it is a iphone 12 by checking the gpu, then we use a mp4 instead of hls as the source video

var deviceType = null

const isAndroidDevice = () => {
  var userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf('android') > -1
}

const useSupportsHlsInWebgl = () => {
  const [supports, setSupports] = useState(true)
  useEffect(() => {
    if (detectIt.primaryInput === 'touch' && !isAndroidDevice()) {
      if (!deviceType) {
        getRenderer((renderer) => {
          deviceType = renderer
          if (deviceType === 'Apple A14 GPU' || deviceType === 'Apple A12 GPU|Apple A13 GPU') {
            setSupports(false)
          }
        })
      } else {
        if (deviceType === 'Apple A14 GPU' || deviceType === 'Apple A12 GPU|Apple A13 GPU') {
          setSupports(false)
        }
      }
    }
  }, [])
  return supports
}

export default useSupportsHlsInWebgl

import { useRef, useEffect } from 'react'
import gsap from 'gsap'

export default (open, onComplete) => {
  const outerRef = useRef()
  const innerRef = useRef()

  useEffect(() => {
    if (outerRef.current && innerRef.current) {
      gsap.set(outerRef.current, { pointerEvents: open ? 'all' : 'none' })
      if (open) {
        gsap.to(outerRef.current, { y: 0, ease: 'expo.out', duration: 1.5, onComplete })
        gsap.to(innerRef.current, { y: 0, ease: 'expo.out', duration: 1.5, onComplete })
      } else {
        gsap.to(outerRef.current, { y: '-100%', ease: 'expo.out', duration: 1.5, onComplete })
        gsap.to(innerRef.current, { y: '100%', ease: 'expo.out', duration: 1.5, onComplete })
      }
    }
  }, [open, onComplete])

  return [outerRef, innerRef]
}

import layout from './layout'
import content from './content'
import config from './config'
import episodes from './episodes'
import cursor from './cursor'
import stage from './stage'
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar'

export default {
  layout,
  content,
  config,
  episodes,
  cursor,
  stage,
  loadingBar
}

/* global window:false */
import inDOM from 'dom-helpers/canUseDOM'
import { current } from '../../style/breakpoints'
import { rehydrated } from '../actions'
import { breakpointChanged, getCurrentBreakpoint } from '../slices/layout'

export default (store) => {
  const check = () => {
    const bp = current()
    const state = store.getState()
    const prev = getCurrentBreakpoint(state)
    if (bp !== prev) {
      store.dispatch(breakpointChanged(bp))
    }
  }
  if (inDOM) {
    window.addEventListener('resize', check)
  }

  return next => (action) => {
    const ret = next(action)
    if (action.type === rehydrated.toString()) {
      check()
    }
    return ret
  }
}

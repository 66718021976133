import inDOM from 'dom-helpers/canUseDOM'
import {
  closeMenu,
  getSelectedEpisodeIndexInMenu,
  navigateToEpisode,
  openMenu
} from '../slices/episodes'
import gsap from 'gsap'
import { endAct, ACT_NAMES, startAct } from '../slices/stage'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import omit from 'lodash/omit'
import filter from 'lodash/filter'
import detectIt from 'detect-it'
import { navigateHome } from '../slices/location'
import { scrollState } from './scrollPager'
import { siteLoaded } from '../slices/layout'

export const stage = {
  timelines: {},
  createTimeline: (store, act, name = 'default', onKill = (timeline) => { timeline.kill() }) => {
    if (stage.timelines[name]) {
      onKill(stage.timelines[name].timeline)
      store.dispatch(endAct(stage.timelines[name].act))
    }
    stage.timelines[name] = {
      timeline: gsap.timeline({
        onComplete: () => {
          stage.timelines[name] = null
          store.dispatch(endAct(act))
        }
      }),
      act
    }
    store.dispatch(startAct(act))
    return stage.timelines[name].timeline
  },
  menu: {
    container: null,
    inner: null,
    list: null,
    videoPreview: null
  },
  episodeContent: {
    content: [],
    socialLinks: null,
    footer: null
  },
  header: {
    logo: null,
    aboutMenu: null,
    episodesMenu: null
  },
  addMenuActors: (container, inner, list, videoPreview) => {
    stage.menu.container = container
    stage.menu.inner = inner
    stage.menu.list = list
    stage.menu.videoPreview = videoPreview
    return () => {
      stage.menu = omit(stage.menu, ['container', 'inner', 'list', 'videoPreview'])
    }
  },
  addEpisodeActors: (content, socialLinks, footer) => {
    stage.episodeContent.content = content
    stage.episodeContent.socialLinks = socialLinks
    stage.episodeContent.footer = footer
    return () => {
      stage.episodeContent = omit(stage.episodeContent, ['content', 'socialLinks', 'footer'])
    }
  },
  addHeaderActors: (logo, aboutMenu, episodesMenu) => {
    stage.header.logo = logo
    stage.header.aboutMenu = aboutMenu
    stage.header.episodesMenu = episodesMenu
    return () => {
      stage.header = omit(stage.header, ['logo', 'aboutMenu', 'episodesMenu'])
    }
  }
}

const getMenuLinks = (list) => {
  return map(list.children, c => ({ linkContainer: c.children[0], link: c.children[0].children[0], line: c.children[1] }))
}

const actPrologue = (store) => {
  const timeline = stage.createTimeline(store, ACT_NAMES.actPrologue)

  // Reset the episode title and copy
  const { content, footer, socialLinks } = stage.episodeContent
  const { lines, copy } = content[0]
  const { logo, aboutMenu, episodesMenu } = stage.header

  // pause the scroller while we transition the title and copy int
  timeline.set(content, {
    onStart: () => {
      scrollState.paused = true
      scrollState.enabled = false
      scrollState.pointerEventsEnabled = false
    }
  })
  timeline.set(lines, { opacity: 1, y: '-100%' })
  timeline.set(copy, { opacity: 0, y: 0 })
  timeline.set([...socialLinks.children, ...footer.children], { opacity: 0, y: '100%' })

  // Animate the header items in
  timeline.to(logo, { opacity: 1, duration: 2, ease: 'sine.inOut' }, 1)
  timeline.to([aboutMenu, episodesMenu], { y: 0, opacity: 1, duration: 1, ease: 'sine.inOut', stagger: 0.2 }, '-=1.5')

  const DURATION = 1.5
  // Animate the footer
  timeline.to([...socialLinks.children, ...footer.children], { opacity: 1, y: '0%', duration: DURATION, ease: 'expo.out', stagger: 0.1 }, '-=0.5')

  // Animate the episode title
  timeline.to(lines, { y: '0%', duration: DURATION, ease: 'power2.out', stagger: 0.2 }, '-=1.5')
  timeline.to(copy, { opacity: 1, y: 0, duration: DURATION, ease: 'power3.out' }, '-=0.75')

  timeline.set(content, {
    onStart: () => {
      scrollState.paused = false
      scrollState.enabled = true
      scrollState.pointerEventsEnabled = true
    }
  })
}

const actMenuToEpisode = (store) => {
  const timeline = stage.createTimeline(store, ACT_NAMES.actMenuToEpisode, 'menu')
  const episodeContentTimeline = stage.createTimeline(store, ACT_NAMES.actMenuToEpisodeContent, 'episode', (timeline) => {}) // Don't kill this timeline
  const selectedIndex = getSelectedEpisodeIndexInMenu(store.getState())

  const { container, inner, list, videoPreview } = stage.menu

  const { width, height } = videoPreview.getBoundingClientRect()
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 }

  timeline.set(inner, { overflowY: 'hidden' })
  timeline.set(videoPreview, { display: 'block' })

  // Stars the video transition that scales the preview to the full screen height and width, cover style
  timeline.to(videoPreview, {
    x: center.x - (width / 2),
    y: center.y - (height / 2) + inner.scrollTop,
    scale: Math.max(window.innerWidth / width, window.innerHeight / height),
    duration: 1.75,
    ease: 'power4.inOut'
  })

  // Transitions the episode links out excluding the selected episode
  const links = getMenuLinks(list)
  const allButSelectedLinks = filter(links, (_, i) => i !== selectedIndex - 1)
  const selectedLink = filter(links, (_, i) => i === selectedIndex - 1)[0]

  // Starts the selected episode link out transition
  timeline.to(selectedLink.linkContainer, { y: '100%', duration: 1.5, ease: 'expo.inOut' }, 0)
  timeline.to(selectedLink.link, { y: '-100%', duration: 1.5, ease: 'expo.inOut' }, 0)
  timeline.to(selectedLink.line, { x: '100%', duration: 1.5, ease: 'expo.inOut' }, 0)

  forEach(allButSelectedLinks, (link, i) => {
    timeline.to(link.linkContainer, { y: '100%', duration: 0.75, ease: 'expo.out' }, 0)
    timeline.to(link.link, { y: '-100%', duration: 0.75, ease: 'expo.out' }, 0)
    timeline.to(link.line, { x: '100%', duration: 0.75, ease: 'expo.out' }, 0)
  })

  // Reset the episode title and copy
  const { content, footer, socialLinks } = stage.episodeContent
  const { lines, copy } = content[selectedIndex]

  // pause the scroller while we transition the title and copy int
  timeline.set(content, { onStart: () => { scrollState.paused = true } })
  timeline.set(lines, { y: '-100%' })
  timeline.set(copy, { opacity: 0, y: 0 })
  timeline.set([...socialLinks.children, ...footer.children], { opacity: 0, y: '100%' })

  // Transition the menu out including the view preview
  timeline.to(container, { opacity: 0, duration: 0.2 })

  // Positions the menu ready for the next transition in
  timeline.set(container, { y: '-100%' })
  timeline.set(inner, { y: '100%' })

  // Resets the video scale and opacity. Also sets the current selected episode to null
  timeline.set(videoPreview, { scale: 1, opacity: 0 })

  // Preview video has completed the scale transition, we can now transition in the episode title and copy
  episodeContentTimeline.to(lines, { y: '0%', duration: 1.5, ease: 'power2.inOut', stagger: 0.2, delay: timeline.duration() - 0.2 })
  episodeContentTimeline.to(copy, { opacity: 1, y: 0, duration: 1.5, ease: 'power2.inOut' }, '-=1')
  episodeContentTimeline.to([...socialLinks.children, ...footer.children], { opacity: 1, y: '0%', duration: 1, ease: 'power2.inOut', stagger: 0.1 }, '-=1')

  episodeContentTimeline.set(content, { onComplete: () => { scrollState.paused = false } })
}

const actMenuIn = (store) => {
  const timeline = stage.createTimeline(store, ACT_NAMES.actMenuIn, 'menu')

  const { container, inner, list, videoPreview } = stage.menu
  // Resets the video preview container
  timeline.set(videoPreview, { display: 'block', clearProps: 'x,y,scale,visibility,opacity' })
  // Resets the menu dialog container
  timeline.set(container, { pointerEvents: 'all', opacity: 1 })
  timeline.set(inner, { overflowY: 'auto' })

  // Transitions the dialog in
  timeline.to(container, { y: 0, duration: 1.5, ease: 'expo.out' })
  timeline.to(inner, { y: 0, duration: 1.5, ease: 'expo.out' }, 0)

  // Transitions the episode links in
  const links = getMenuLinks(list)
  const timelines = map(links, (link, i) => {
    const tl = gsap.timeline()
    tl.set(link.linkContainer, { y: 0 })
    tl.fromTo(link.link, { y: '100%' }, { y: '0%', duration: 0.75, ease: 'sine.out' }, 0.25 + i * 0.05)
    tl.fromTo(link.line, { x: '-100%' }, { x: '0%', duration: 0.75, ease: 'sine.out' }, 0.25 + i * 0.05)
  })
  forEach(timelines, (tl, i) => {
    timeline.add(tl)
  })
}

const actMenuOut = (store) => {
  const timeline = stage.createTimeline(store, ACT_NAMES.actMenuOut, 'menu')

  const { container, inner } = stage.menu
  timeline.set(container, { pointerEvents: 'none' })
  timeline.to(container, { y: '-100%', duration: 1.5, ease: 'expo.out' }, 0.2)
  timeline.to(inner, { y: '100%', duration: 1.5, ease: 'expo.out' }, 0.2)
}

export default store => next => action => {
  if (!inDOM) return next(action)

  var result = next(action)

  switch (action.type) {
    case siteLoaded.toString():
      actPrologue(store)
      break
    case navigateToEpisode.toString():
      if (detectIt.primaryInput !== 'touch') {
        actMenuToEpisode(store)
      } else {
        store.dispatch(closeMenu())
      }
      break
    case openMenu.toString():
      actMenuIn(store)
      break
    case closeMenu.toString():
      actMenuOut(store)
      break
    case navigateHome.toString():
    case 'route/content':
      actMenuOut(store)
      break
  }

  return result
}

import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../RichText'
import cn from 'classnames'
import theme from '../../style/theme'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'
import composeRefs from '../../helpers/composeRefs'
import Divider from './Divider'

const RichTextSlice = ({ slice: { text, span, hideOnMobile, showDivider } }) => {
  const classes = useStyles({ span })
  const [inViewRef, inView] = useInView({ threshold: 0.2 })
  const ref = useRef()
  useEffect(() => {
    if (inView) {
      gsap.to(ref.current, { opacity: 1, y: 0, ease: 'expo.out', duration: 2.75 })
    }
  }, [inView])

  return (
    <>
      <RichText className={cn(classes.content, { hideOnMobile })} content={text} ref={composeRefs(inViewRef, ref)} />
      {showDivider && <Divider />}
    </>
  )
}

const useStyles = createUseStyles({
  content: {
    gridColumn: ({ span }) => `span ${span || 1}`,
    '&.hideOnMobile': {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    opacity: 0,
    transform: 'translateY(-32px)'
  }
}, { name: 'RichTextSlice' })

export default RichTextSlice

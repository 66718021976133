import { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import detectIt from 'detect-it'

const useHlsPlayer = (playerRef, source, onReady, enabled = true) => {
  const hlsRef = useRef
  useEffect(() => {
    if (enabled && source && playerRef.current) {
      const video = playerRef.current
      if (Hls.isSupported()) {
        var hls = new Hls({
          startLevel: detectIt.primaryInput === 'touch' ? undefined : '3',
          maxBufferLength: 2,
          maxBufferSize: detectIt.primaryInput === 'touch' ? 5 : 10
        })
        hlsRef.current = hls
        hls.loadSource(source)
        hls.attachMedia(playerRef.current)
        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                console.log('fatal network error encountered, try to recover')
                hls.startLoad()
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.log('fatal media error encountered, try to recover')
                hls.recoverMediaError()
                break
              default:
                hls.destroy()
                break
            }
          }
        })
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (onReady) {
            onReady(video, hls)
          }
        })
        return () => {
          hls.detachMedia()
          hls.destroy()
          hlsRef.current = null
        }
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        const handleLoaded = () => {
          if (onReady) {
            onReady(video)
          }
        }
        video.src = source
        video.addEventListener('loadedmetadata', handleLoaded)
        return () => {
          video.removeEventListener('loadedmetadata', handleLoaded)
        }
      }
    }
  }, [source, enabled])
}

export default useHlsPlayer

import React, { useRef, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getEpisodes } from '../../redux/slices/content'
import { usePageScrollListener } from '../../redux/middlewares/scrollPager'
import { getCurrentEpisode } from '../../redux/slices/episodes'
import theme from '../../style/theme'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import SocialLinks from './SocialLinks'
import WatchNowLink from '../WatchNowLink'
// import gsap from 'gsap'
import round from '../../helpers/round'
import { stage } from '../../redux/middlewares/stage'

const getCurrentContentElements = (contentContainerRef, page) => {
  const content = contentContainerRef.current.children[page]
  const meta = content.children[0]
  const copy = content.children[1]
  const title = content.children[2]
  const lines = map(title.children, x => x.children[0])
  return {
    content,
    copy: [copy, meta],
    title,
    lines
  }
}

export default () => {
  const classes = useStyles()
  const episodes = useSelector(getEpisodes).episodes
  const contentContainerRef = useRef()
  const episode = useSelector(getCurrentEpisode)
  const socialLinksRef = useRef()
  const footerRef = useRef()

  const localsRef = useRef([])

  useEffect(() => {
    localsRef.current = []
    forEach(contentContainerRef.current.children, (content, i) => {
      const elements = getCurrentContentElements(contentContainerRef, i)
      localsRef.current.push(elements)
    })
    stage.addEpisodeActors(localsRef.current, socialLinksRef.current, footerRef.current)
  }, [])

  usePageScrollListener(useCallback((scrollState) => {
    if (!scrollState.paused) {
      forEach(localsRef.current, (elements, i) => {
        const { lines, copy, content } = elements
        const dist = (1 - Math.min(Math.abs(scrollState.position - i), 1)) ** 4
        if (dist > 0 || scrollState.previousPage === i) {
          content.style.zIndex = Math.round(dist)
          forEach(copy, c => {
            const y = round((i - scrollState.position) * -80)
            const opacity = round(dist)
            c.style.transform = `translate3d(0, ${y}px, 0)`
            c.style.opacity = opacity
          })
          forEach(lines, line => {
            const y = round((i - scrollState.position) * -100 * 2)
            line.style.opacity = round(dist * 1.5)
            line.style.transform = `translate3d(0, ${y}%, 0)`
          })
        }
      })
    }
  }, []))

  return (
    <>
      <span className={classes.contentContainer} ref={contentContainerRef} id='episode-content'>
        {episodes.map((episode, i) => {
          const SrTitleElement = i === 0 ? 'h1' : 'h2'
          return (
            <span key={episode.title} className={classes.contentWrapper}>
              <div className={classes.meta}>
                <span className={classes.episodeNumber}>
                  {episode && (episode.sourceType === 'trailer' ? 'Trailer' : `Episode ${episode.episodeNumber}`)}
                </span>
                <span className={classes.runningTime}>
                  {episode.runningTime}
                </span>
                <span className={classes.watchNowLinks}>
                  <WatchNowLink className={classes.link} />
                </span>
              </div>
              <span className={classes.copy}>{episode.copy}</span>
              <div className={classes.title}>
                {episode.titleVectorImages ? episode.titleVectorImages.map((vectorImage, i) => (
                  <div key={i} className={classes.titleLine}>
                    <img src={vectorImage.titleLine.sizes[0].url} />
                  </div>
                )) : (
                  <div className={classes.titleLine}>
                    <span>{episode.title}</span>
                  </div>
                )}
              </div>

              <SrTitleElement className='srOnly'>{episode.title}</SrTitleElement>
            </span>
          )
        })}
      </span>
      <SocialLinks className={classes.socialLinks} ref={socialLinksRef} />
      <div className={classes.metaFooter} ref={footerRef}>
        <span className={classes.episodeNumber}>
          {episode && (episode.sourceType === 'trailer' ? 'Trailer' : `Episode ${episode.episodeNumber}`)}
        </span>
        <span className={classes.runningTime}>
          {episode.runningTime}
        </span>
        <span className={classes.watchNowLinks}>
          <WatchNowLink className={classes.link} />
        </span>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  contentContainer: {
    width: '100%',
    display: 'block',
    position: 'relative',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0.5)
    }
  },
  contentWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  meta: {
    order: 1,
    opacity: 0,
    display: 'flex',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  metaFooter: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
    '& > *': {
      opacity: 0,
      transform: 'translate(0, 100%)'
    }
  },
  title: {
    width: '100%',
    display: 'block',
    order: 2,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      order: 3,
      marginBottom: 0
    }
  },
  titleLine: {
    overflow: 'hidden',
    display: 'block',
    '& img, & span': {
      width: '100%',
      opacity: 0
    }
  },
  copy: {
    fontSize: 14,
    maxWidth: 480,
    display: 'block',
    marginBottom: 0,
    opacity: 0,
    order: 3,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
      order: 2
    }
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 12
  },
  socialLinks: {
    composes: ['$label'],
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '33%'
    },
    '& > *': {
      opacity: 0,
      transform: 'translate(0, 100%)'
    }
  },
  episodeNumber: {
    composes: ['$label'],
    display: 'block',
    width: '50%'
  },
  runningTime: {
    composes: ['$label'],
    display: 'block'
  },
  watchNowLinks: {
    composes: ['$label'],
    textAlign: 'right',
    display: 'block',
    flexGrow: 1
  },
  link: {
    display: 'inline-block',
    fontSize: 12
  }
})

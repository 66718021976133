import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { selectEpisodeInMenu } from '../../redux/slices/episodes'
// import gsap from 'gsap'
import theme from '../../style/theme'
import MenuItem from './MenuItem'
import { getEpisodes } from '../../redux/slices/content'
import filter from 'lodash/filter'
// import map from 'lodash/map'
// import forEach from 'lodash/forEach'
import { useHoverAnimation } from '../Cursor'
import detectIt from 'detect-it'
// import PreviewVideoSources from '../VideoPlayer/PreviewVideoSources'
import { ACT_NAMES, isActRunning } from '../../redux/slices/stage'
import { stage } from '../../redux/middlewares/stage'
import PreviewVideo from './PreviewVideo'

const useGetEpisodes = () => {
  const episodes = useSelector(getEpisodes).episodes
  return useMemo(() => {
    return filter(episodes, e => e.sourceType === 'episode')
  }, [episodes])
}

export default () => {
  const classes = useStyles()
  // const open = useSelector(isMenuOpen)
  const ref = useRef()
  const episodes = useGetEpisodes()
  const dispatch = useDispatch()
  const innerRef = useRef()
  const previewVideoRef = useRef()
  const isActMenuToEpisodeRunning = useSelector(isActRunning(ACT_NAMES.actMenuToEpisode))

  const listRef = useHoverAnimation('View', theme.colors.white, !isActMenuToEpisodeRunning)

  useEffect(() => {
    if (ref.current) {
      return stage.addMenuActors(ref.current, innerRef.current, listRef.current, previewVideoRef.current)
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    if (!isActMenuToEpisodeRunning) {
      dispatch(selectEpisodeInMenu(null))
    }
  }, [isActMenuToEpisodeRunning])

  return (
    <section ref={ref} className={classes.container} id='menu'>
      <div className={classes.inner} ref={innerRef}>
        <PreviewVideo ref={previewVideoRef} />
        <nav className={classes.nav}>
          <ul className={classes.list} ref={listRef} onMouseLeave={detectIt.primaryInput === 'touch' ? null : onMouseLeave}>
            {episodes.map(e => <MenuItem key={e.title} episode={e} disabled={isActMenuToEpisodeRunning} />)}
          </ul>
        </nav>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    backgroundColor: theme.colors.black,
    zIndex: theme.zIndex.menu,
    transform: 'translate(0, -100%)',
    overflowX: 'hidden'
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: 'translate(0, 100%)'
  },
  nav: {
    height: '100%',
    padding: [theme.spacing(4), theme.spacing(1), theme.spacing(4)],
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      margin: [theme.spacing(12), theme.spacing(1), theme.spacing(16)],
      display: 'block'
    }
  },
  list: {
    listStyle: 'none',
    // margin: [theme.spacing(4), 0, theme.spacing(4), 0],
    margin: 0,
    padding: 0
    // [theme.breakpoints.up('sm')]: {
    //   margin: [theme.spacing(12), 0, theme.spacing(8), 0]
    // }
  }
})

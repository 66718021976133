import { isHomepage } from '../slices/content'
import {
  closeMenu,
  closeWatchNowDropdown,
  isMenuOpen,
  isWatchNowDropdownOpen,
  openMenu,
  openWatchNowDropdown,
  toggleMenu,
  toggleWatchNowDropdown
} from '../slices/episodes'
import { getOptions, pathToAction, selectLocationState } from 'redux-first-router'

export const navigateActionCreator = (state, pathName) => {
  const { querySerializer } = getOptions()
  const { routesMap } = selectLocationState(state)
  return pathToAction(pathName, routesMap, querySerializer)
}

export default store => next => action => {
  if (action.type === toggleMenu.toString()) {
    const open = isMenuOpen(store.getState())
    const homepage = isHomepage(store.getState())
    if (!open && !homepage) {
      store.dispatch(navigateActionCreator(store.getState(), '/'))
    }
    return next(open ? closeMenu() : openMenu())
  }

  if (action.type === toggleWatchNowDropdown.toString()) {
    const open = isWatchNowDropdownOpen(store.getState())
    return next(open ? closeWatchNowDropdown(action.payload) : openWatchNowDropdown(action.payload))
  }

  return next(action)
}

import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import AnimatedLink from './AnimatedLink'
import { useDispatch, useSelector } from 'react-redux'
import {
  getWatchNowLinks,
  isWatchNowDropdownOpen,
  toggleWatchNowDropdown,
  getWatchNowLabel
} from '../redux/slices/episodes'
import isEmpty from 'lodash/isEmpty'

export default ({ className }) => {
  const classes = useStyles()
  const watchNowLinks = useSelector(getWatchNowLinks)
  const watchNowLabel = useSelector(getWatchNowLabel)
  const open = useSelector(isWatchNowDropdownOpen)
  const dispatch = useDispatch()
  const ref = useRef()

  const onClick = useCallback(() => {
    const rect = ref.current.getBoundingClientRect()
    dispatch(toggleWatchNowDropdown(rect))
  }, [open])

  const hasWatchNowLinks = !isEmpty(watchNowLinks)

  if (!hasWatchNowLinks && !watchNowLabel) return null
  return (
    <div className={cn(classes.className, classes.container)}>
      {!hasWatchNowLinks
        ? <span className={classes.link}>{watchNowLabel}</span>
        : <AnimatedLink className={classes.link} onClick={onClick} text={watchNowLabel || 'Watch Now'} ref={ref} />}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  link: {
    fontSize: 12
  }
})

import layoutMiddleware from './layout'
import googleTagsMiddleware from './googleTags'
import scrollPagerMiddleware from './scrollPager'
import videoPlayerMiddleware from './videoPlayer'
import episodesMiddleware from './episodes'
import stageMiddleware from './stage'
import rejectedPromiseMiddleware from './rejectedPromise'
import promiseMiddleware from 'redux-promise-middleware'

export default [
  layoutMiddleware,
  episodesMiddleware,
  stageMiddleware,
  scrollPagerMiddleware,
  videoPlayerMiddleware,
  googleTagsMiddleware,
  rejectedPromiseMiddleware,
  promiseMiddleware
]

import { createSlice } from '@reduxjs/toolkit'
import { navigateToEpisode } from './episodes'
import get from 'lodash/get'
import find from 'lodash/find'

export const ACT_NAMES = {
  actPrologue: 'actPrologue',
  actMenuToEpisode: 'actMenuToEpisode',
  actMenuIn: 'actMenuIn',
  actMenuOut: 'actMenuOut',
  actMenuToEpisodeContent: 'actMenuToEpisodeContent'
}

const stageSlice = createSlice({
  name: 'stage',
  initialState: {
    acts: {}
  },
  reducers: {
    startAct: (state, action) => {
      if (!state.acts[action.payload]) {
        state.acts[action.payload] = {}
      }
      state.acts[action.payload].running = true
    },
    endAct: (state, action) => {
      state.acts[action.payload].running = false
    }
  },
  extraReducers: {
    [navigateToEpisode]: (state, action) => {
      if (!state.acts[ACT_NAMES.actMenuToEpisode]) {
        state.acts[ACT_NAMES.actMenuToEpisode] = {}
      }
      state.acts[ACT_NAMES.actMenuToEpisode].running = true
    }
  }
})

export const {
  startAct,
  endAct
} = stageSlice.actions

export const isActRunning = (act) => state => get(state.stage, ['acts', act, 'running'])
export const isAnyActRunning = state => find(get(state.stage, ['acts'], []), act => act.running)

export default stageSlice.reducer

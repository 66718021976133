import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'
import composeRefs from '../../helpers/composeRefs'
import Divider from './Divider'
import SplitText from 'gsap/SplitText'

gsap.registerPlugin(SplitText)

const wrap = (toWrap, wrapper) => {
  wrapper = wrapper || document.createElement('div')
  toWrap.parentNode.appendChild(wrapper)
  wrapper.appendChild(toWrap)
  return wrapper
}

const unwrap = (wrapped) => {
  wrapped.parentNode.appendChild(wrapped.children[0])
  wrapped.parentNode.removeChild(wrapped)
}

const TitleSlice = ({ slice: { tag, title } }) => {
  const classes = useStyles()
  const [inViewRef, inView] = useInView({ threshold: 0.2 })
  const ref = useRef()
  const localsRef = useRef({ wrappedLines: [], lines: [] })

  useEffect(() => {
    if (ref.current) {
      ref.current.style.width = ref.current.getBoundingClientRect().width + 'px'
      var split = new SplitText(ref.current, { type: 'lines' })
      split.lines.forEach(line => {
        localsRef.current.wrappedLines.push(wrap(line))
      })
      localsRef.current.lines = split.lines

      const { lines, wrappedLines } = localsRef.current
      gsap.set(wrappedLines, { overflow: 'hidden', y: '-100%' })
      gsap.set(lines, { opacity: 0, y: '100%' })
      gsap.set(ref.current, { opacity: 1 })

      return () => {
        wrappedLines.forEach(line => {
          unwrap(line)
        })
        split.revert()
        localsRef.current.wrappedLines = []
        localsRef.current.lines = []
      }
    }
  }, [])

  useEffect(() => {
    if (inView) {
      const { lines, wrappedLines } = localsRef.current
      gsap.to(wrappedLines, { opacity: 1, y: '0%', ease: 'expo.out', duration: 2 })
      gsap.to(lines, { opacity: 1, y: '0%', ease: 'expo.out', duration: 2 })
    }
  }, [inView])

  const Tag = tag || 'h1'

  return (
    <>
      <Tag className={classes.content} ref={composeRefs(inViewRef, ref)} dangerouslySetInnerHTML={{ __html: title.replace('\n', '<br />') }} />
      <Divider />
    </>
  )
}

const useStyles = createUseStyles({
  content: {
    gridColumn: 'span 3',
    opacity: 0,
    whiteSpace: 'pre-wrap'
  }
}, { name: 'TitleSlice' })

export default TitleSlice

import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const pageContentActionCreator = createAction('page/content', (payload) => ({
  payload,
  meta: { scope: 'page' }
}))
export const serverErrorActionCreator = createAction('page/serverError')

export const clearPageContent = createAction('page/content_clearPageContent')

const pageSlice = makeFetchSlice('page', pageContentActionCreator, serverErrorActionCreator)

export default pageSlice.reducer

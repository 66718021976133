import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import gsap from 'gsap'
import detectIt from 'detect-it'
import lerp from 'lerp'
import afterFrame from '../helpers/afterFrame'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBackgroundColor,
  getText,
  hide,
  isHovering,
  isLoading,
  setHovering,
  show,
  isVisible
} from '../redux/slices/cursor'
import Color from 'color'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'

const CURSOR_ENABLED = true

const EASE = 0.3

const mouseMoveCallbacks = []

export const data = {
  mouse: {
    x: 0,
    y: 0
  },
  current: {
    x: undefined,
    y: undefined
  },
  last: {
    x: undefined,
    y: undefined
  },
  ease: EASE,
  fx: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  },
  fy: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  },
  state: {
    scale: 0.12,
    backgroundColor: theme.colors.white,
    foregroundColor: theme.colors.black,
    hovering: false
  },
  defaults: {
    backgroundColor: theme.colors.white
  },
  element: null,
  timeline: gsap.timeline()
}

export const useCursorPosition = (mouseMoveCallback, enabled = true) => {
  useEffect(() => {
    if (enabled) {
      mouseMoveCallbacks.push(mouseMoveCallback)
      return () => {
        remove(mouseMoveCallbacks, x => x === mouseMoveCallback)
      }
    }
  }, [mouseMoveCallback, enabled])
}

export const useHideCursorOnIdle = (enabled, idleMs = 1000) => {
  const dispatch = useDispatch()
  const visibleRef = useRef(true)
  useEffect(() => {
    if (enabled && detectIt.primaryInput !== 'hover') {
      var timeout
      const hideCursor = () => {
        visibleRef.current = false
        dispatch(hide())
      }
      const onMouseMove = () => {
        if (!visibleRef.current) {
          visibleRef.current = true
          dispatch(show())
        }
        clearTimeout(timeout)
        timeout = setTimeout(hideCursor, idleMs)
      }
      timeout = setTimeout(hideCursor, idleMs)
      document.body.addEventListener('mousemove', onMouseMove)
      return () => {
        if (!visibleRef.current) {
          visibleRef.current = true
          dispatch(show())
        }
        clearTimeout(timeout)
        document.body.removeEventListener('mousemove', onMouseMove)
      }
    }
  }, [enabled])
}

export const useHoverAnimation = (text, backgroundColor = theme.colors.black, enabled = true) => {
  const ref = useRef()
  const isHoveringRef = useRef(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isHoveringRef.current) {
      dispatch(setHovering({ hovering: true, text, backgroundColor }))
    }
  }, [text])

  useEffect(() => {
    if (detectIt.primaryInput !== 'touch') {
      if (enabled) {
        const onEnter = () => {
          dispatch(setHovering({ hovering: true, text: text, backgroundColor }))
          isHoveringRef.current = true
        }
        const onLeave = () => {
          dispatch(setHovering({ hovering: false, text: '', backgroundColor: data.defaults.backgroundColor }))
          isHoveringRef.current = false
        }
        const onMouseMove = () => {
          if (!data.state.hovering) {
            dispatch(setHovering({ hovering: true, text: text, backgroundColor }))
            isHoveringRef.current = true
          }
        }
        ref.current.addEventListener('mouseenter', onEnter)
        ref.current.addEventListener('mouseleave', onLeave)
        ref.current.addEventListener('mousemove', onMouseMove)
        return () => {
          if (isHoveringRef.current) {
            onLeave()
          }
          ref.current.removeEventListener('mouseenter', onEnter)
          ref.current.removeEventListener('mouseleave', onLeave)
          ref.current.removeEventListener('mousemove', onMouseMove)
        }
      }
    }
  }, [text, backgroundColor, enabled])

  return ref
}

const getForegroundColor = (backgroundColor) => {
  const c = Color(backgroundColor)
  return c.isLight() ? theme.colors.black : theme.colors.white
}

const animateIn = (backgroundColor, grow = true) => {
  data.timeline.kill()
  data.timeline = gsap.timeline()
  if (grow) {
    data.timeline.to(data.state, { scale: 1, ease: 'elastic.out(1, 0.3)', duration: 0.55 })
  }
  data.timeline.to(data.state, { backgroundColor, foregroundColor: getForegroundColor(backgroundColor), ease: 'expo.out', duration: 0.25 }, 0)
}

const animateOut = (backgroundColor) => {
  data.timeline.kill()
  data.timeline = gsap.timeline()
  data.timeline.to(data.state, { scale: 0.12, ease: 'sine.out', duration: 0.25 })
  data.timeline.to(data.state, { backgroundColor, foregroundColor: getForegroundColor(backgroundColor), ease: 'expo.out', duration: 0.5 }, 0)
}

const animateHide = () => {
  data.timeline.kill()
  data.timeline = gsap.timeline()
  data.timeline.to(data.state, { scale: 0, ease: 'sine.out', duration: 0.25 })
}

function Cursor ({ children }) {
  const ref = useRef()
  const classes = useStyles()
  const hovering = useSelector(isHovering)
  const loading = useSelector(isLoading)
  const text = useSelector(getText)
  const backgroundColor = useSelector(getBackgroundColor)
  const visible = useSelector(isVisible)

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current) {
      gsap.set(ref.current, { display: 'flex' })
    }
  }, [])

  useEffect(() => {
    if (visible) {
      if (loading || hovering) {
        animateIn(backgroundColor, !!text)
      } else {
        animateOut(backgroundColor)
      }
    } else {
      animateHide()
    }
    data.state.hovering = hovering
  }, [hovering, loading, backgroundColor, text, visible])

  useEffect(() => {
    data.element = ref.current
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      const updateMousePosition = (evnt) => {
        afterFrame(() => {
          if (ref.current) {
            ref.current.style.opacity = 1
            const e = evnt.detail && evnt.detail.pageX ? evnt.detail : evnt
            data.mouse.x = e.pageX
            data.mouse.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)

            data.current.x = e.pageX
            data.current.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)
            forEach(mouseMoveCallbacks, cb => cb(data, evnt))
          }
        })
      }
      window.addEventListener('mousemove', updateMousePosition, { passive: true })
      window.addEventListener('dragover', updateMousePosition, { passive: true })
      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
        window.removeEventListener('dragover', updateMousePosition)
      }
    }
  }, [])

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      const track = () => {
        if (ref.current && data.current.x !== undefined) {
          // on the initial mouse move, we do not want any easing. Just put the mouse on the cursor
          if (data.last.x === undefined) {
            data.last.x = data.current.x
            data.last.y = data.current.y
          } else {
            data.last.x = lerp(data.last.x, data.current.x, data.ease)
            data.last.y = lerp(data.last.y, data.current.y, data.ease)
          }

          data.fx.diff = data.current.x - data.last.x
          data.fx.acc = data.fx.diff / window.innerWidth
          data.fx.velocity = +data.fx.acc

          data.fy.diff = data.current.y - data.last.y
          data.fy.acc = data.fy.diff / window.innerWidth
          data.fy.velocity = +data.fy.acc

          ref.current.style.backgroundColor = data.state.backgroundColor
          ref.current.style.color = data.state.foregroundColor
          ref.current.style.transform = `translate3d(${data.last.x}px, ${data.last.y}px, 0) scale(${data.state.scale})`
        }
      }
      gsap.ticker.add(track)
      return () => {
        gsap.ticker.remove(track)
      }
    }
  }, [])

  if (!CURSOR_ENABLED) return null

  return (
    <>
      {children}
      <div className={classes.cursor} ref={ref} id='cursor'>
        <span className={classes.text}>{loading ? 'Loading' : text}</span>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  cursor: {
    fontSize: 100,
    position: 'fixed',
    width: '1em',
    height: '1em',
    top: '-0.5em',
    left: '-0.5em',
    pointerEvents: 'none',
    zIndex: theme.zIndex.cursor,
    opacity: 0,
    backgroundColor: theme.colors.white,
    color: theme.colors.white,
    borderRadius: '50%',
    transform: 'scale(0.12)',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: 16,
    textTransform: 'uppercase'
  }
}, { name: 'Cursor' })

export default Cursor

import React, { useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { useHideCursorOnIdle, useHoverAnimation } from '../Cursor'
import detectIt from 'detect-it'
import { useDispatch, useSelector } from 'react-redux'
import { isReady } from '../../redux/slices/layout'
import gsap from 'gsap'
import { isPlaying, playEpisode, pauseEpisode } from '../../redux/slices/episodes'
import reduce from 'lodash/reduce'

export default () => {
  const classes = useStyles()
  const ready = useSelector(isReady)
  const playing = useSelector(isPlaying)
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    if (ready) {
      if (playing) {
        dispatch(pauseEpisode())
      } else {
        dispatch(playEpisode())
      }
    }
  }, [ready, playing])

  const ref = useHoverAnimation(playing ? 'Stop' : 'Play', playing ? theme.colors.white : theme.colors.black)

  useHideCursorOnIdle(playing)

  useEffect(() => {
    if (ready && detectIt.primaryInput === 'touch') {
      gsap.to(ref.current, { opacity: 1, duration: 0.45 })

      // The code below will move the button so that it site between the content and the header
      const header = document.getElementById('header')
      const episodeContent = document.getElementById('episode-content').children
      const contentTop = reduce(episodeContent, (top, element) => {
        const elementTop = element.getBoundingClientRect().top
        if (top === -1 || elementTop < top) return elementTop
        return top
      }, -1)
      const headerBottom = header.getBoundingClientRect().bottom
      const buttonHeight = ref.current.getBoundingClientRect().height
      const position = headerBottom + ((contentTop - headerBottom) / 2) - (buttonHeight / 2)
      gsap.set(ref.current, { y: position })
    }
  }, [ready])

  return (
    <>
      <button
        className={classes.playButton}
        onClick={detectIt.primaryInput !== 'touch' ? onClick : null}
        onMouseUp={detectIt.primaryInput === 'touch' ? onClick : null}
        ref={ref}
      >
        {playing ? detectIt.primaryInput === 'touch' ? 'Loading' : 'Stop' : 'Play'}
      </button>
    </>
  )
}

const useStyles = createUseStyles({
  playButton: {
    position: 'absolute',
    width: 100,
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 0%)',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    outline: 'none',
    borderRadius: '50%',
    borderWidth: 0,
    textDecoration: 'none',
    backgroundColor: theme.colors.black,
    zIndex: theme.zIndex.playButton,
    opacity: 0,
    fontSize: 16,
    color: theme.colors.white,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      cursor: 'none',
      transform: 'translate(0, 0)',
      backgroundColor: 'transparent',
      zIndex: 2,
      width: '100%',
      top: 0,
      left: 0,
      height: '100%',
      borderRadius: '50%'
    }
  }
})

import { createSlice, combineReducers } from '@reduxjs/toolkit'
import preview from './preview'
import global from './global'
import page from './page'

import { rehydrated } from '../../actions'

export * from './selectors'

const rehydratedSlice = createSlice({
  name: 'content',
  initialState: false,
  extraReducers: builder =>
    builder
      .addCase(rehydrated.toString(), (state, action) => {
        return true
      })
})

export default combineReducers({
  global,
  page,
  preview,
  rehydrated: rehydratedSlice.reducer
})

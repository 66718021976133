import React, { useRef, useEffect, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import useHlsPlayer from '../../hooks/useHlsPlayer'
import { videoLoaded } from '../../redux/slices/layout'
import { getSelectedEpisodeIndexInMenu } from '../../redux/slices/episodes'
import detectIt from 'detect-it'
import { usePageScrollListener } from '../../redux/middlewares/scrollPager'
import { getHlsSourceFromVimeo, getMp4SourcesFromVimeo } from '../../helpers/video'
import find from 'lodash/find'
import last from 'lodash/last'
import useSupportsHlsInWebgl from '../../hooks/useSupportHlsInWebgl'
import { getEpisodes } from '../../redux/slices/content'

const useVimeoSourceUrl = (video, mp4Source) => {
  const [url, setUrl] = useState(getHlsSourceFromVimeo(video).url)
  useEffect(() => {
    if (mp4Source) {
      // We need to get the redirected url directly as setting the source to the redirect the iphones will throw a security exception
      const redirectUrl = (find(getMp4SourcesFromVimeo(video), x => x.width >= 1280) || last(getMp4SourcesFromVimeo(video))).url
      window.fetch(redirectUrl, {
        method: 'HEAD'
      })
        .then((response) => {
          setUrl(response.url)
        })
    }
  }, [mp4Source])
  return url
}

const canPlayVideos = []

const PreviewVideoSource = ({ video, index, useMp4Source = false }) => {
  const classes = useStyles()
  const playerRef = useRef()
  const dispatch = useDispatch()
  const [playing, setPlaying] = useState(index === 0)
  const [preload, setPreload] = useState(index === 0)
  const selectedEpisodeIndex = useSelector(getSelectedEpisodeIndexInMenu)
  const localsRef = useRef({ playPromise: null })

  usePageScrollListener(useCallback(scrollState => {
    var playing = scrollState.position > index - 0.9 && scrollState.position < index + 0.9
    if (detectIt.primaryInput !== 'touch') {
      playing = selectedEpisodeIndex === index || playing
    }
    setPlaying(playing)

    if (!preload) {
      const shouldPreload = (canPlayVideos[index - 1]) || playing
      if (shouldPreload) {
        setPreload(true)
      }
    }
  }, [selectedEpisodeIndex, preload]))

  const url = useVimeoSourceUrl(video, useMp4Source)

  useHlsPlayer(playerRef, (preload || playing) && url, () => {
    if (playerRef.current) {
      if (playing) {
        localsRef.current.playPromise = playerRef.current.play()
      } else {
        if (playerRef.current && playerRef.current.seek) {
          playerRef.current.seek(0)
        }
      }
    }
  }, !useMp4Source)

  useEffect(() => {
    const onCanPlay = () => {
      canPlayVideos[index] = true // This indicates that the current video is ready so you can preload the next video
      if (index === 0) {
        dispatch(videoLoaded())
      }
    }
    playerRef.current.addEventListener('canplay', onCanPlay)
    return () => {
      playerRef.current.removeEventListener('canplay', onCanPlay)
    }
  }, [index])

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        localsRef.current.playPromise = playerRef.current.play()
      } else {
        if (localsRef.current.playPromise) {
          localsRef.current.playPromise.then(() => {
            playerRef.current.pause()
          })
        }
      }
    }
  }, [playing])

  return (
    <video
      ref={playerRef}
      src={preload || playing ? url : ''}
      className={classes.video}
      controls={false}
      autoPlay={index === 0}
      preload='metadata'
      muted
      loop
      playsInline
      crossOrigin='anonymous'
    />
  )
}

const VideoSources = () => {
  const classes = useStyles()
  const useMp3Source = !useSupportsHlsInWebgl()
  const episodes = useSelector(getEpisodes).episodes
  const containerRef = useRef()

  return (
    <div id='videos-sources' className={classes.container} ref={containerRef}>
      {episodes.map((episode, key) => (
        <PreviewVideoSource
          key={key}
          video={episode.vimeoBackgroundVideo}
          index={key}
          useMp4Source={useMp3Source}
        />
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: 450,
    height: 250,
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    display: 'none'
  },
  video: {
    width: 450,
    height: 250,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'opacity 0.15s ease-in-out'
  }
}, { name: 'PreviewVideoSource' })

export default VideoSources

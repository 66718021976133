import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import gsap from 'gsap'

export default ({ className, animate }) => {
  const classes = useStyles()
  const logoRef = useRef()
  const logoRef2 = useRef()

  useEffect(() => {
    if (animate) {
      var tl = gsap.timeline({ repeat: -1 })
      tl.to([logoRef.current, logoRef2.current], { rotate: 360, duration: 2, transformOrigin: '50% 50%', ease: 'none' })
      return () => {
        tl.kill()
        gsap.to([logoRef.current, logoRef2.current], { rotate: 360, duration: 1, transformOrigin: '50% 50%', ease: 'none' })
      }
    }
  }, [animate])

  return (
    <>
      <svg className={cn(classes.desktopLogo, className)} viewBox='0 0 272 17'>
        <path d='M2.74885 2.72903V7.73934H10.0206V10.0983H2.74885V16.6487H0V0.350586H10.5665V2.72903H2.74885Z' fill='currentColor' />
        <path d='M23.4919 1.03326C24.6226 1.7156 25.4999 2.70986 26.1238 3.97706C26.7281 5.24427 27.0401 6.76491 27.0401 8.5C27.0401 10.2351 26.7281 11.7557 26.1238 13.0229C25.5194 14.2901 24.6421 15.2844 23.4919 15.9667C22.3612 16.6491 21.016 17 19.4758 17C17.9357 17 16.5905 16.6491 15.4598 15.9667C14.3291 15.2844 13.4518 14.2901 12.8279 13.0229C12.2235 11.7557 11.9116 10.2351 11.9116 8.5C11.9116 6.76491 12.2235 5.24427 12.8279 3.97706C13.4323 2.70986 14.3096 1.7156 15.4598 1.03326C16.5905 0.350917 17.9357 0 19.4758 0C21.016 0 22.3612 0.350917 23.4919 1.03326ZM16.0252 4.07454C15.1869 5.16628 14.758 6.62844 14.758 8.5C14.758 10.3716 15.1869 11.8337 16.0252 12.9255C16.8635 14.0172 18.0137 14.5436 19.4758 14.5436C20.9185 14.5436 22.0687 13.9977 22.9265 12.9255C23.7648 11.8337 24.1937 10.3716 24.1937 8.5C24.1937 6.62844 23.7648 5.16628 22.9265 4.07454C22.0882 2.9828 20.938 2.45642 19.4758 2.45642C18.0332 2.45642 16.883 3.00229 16.0252 4.07454Z' fill='currentColor' />
        <path d='M39.7119 1.52031C40.6672 2.30013 41.1545 3.41137 41.1545 4.87352C41.1545 5.88728 40.8816 6.76458 40.3357 7.48591C39.7899 8.20724 39.049 8.67513 38.1327 8.90907V8.94806C38.7176 9.14302 39.166 9.45494 39.5169 9.88384C39.8483 10.3127 40.1993 11.0341 40.5697 12.0673L42.2463 16.6682H39.244L37.8598 12.5742C37.5479 11.6384 37.158 10.9951 36.7291 10.6442C36.3002 10.2932 35.6568 10.1178 34.838 10.1178H32.4791V16.6682H29.7302V0.350586H35.3449C37.2944 0.350586 38.7371 0.740494 39.7119 1.52031ZM32.4596 7.85632H35.1889C36.2807 7.85632 37.08 7.62237 37.5869 7.15448C38.1133 6.68659 38.3667 6.02375 38.3667 5.20494C38.3667 3.46985 37.2555 2.61205 35.0135 2.61205H32.4401V7.85632H32.4596Z' fill='currentColor' />
        <path d='M56.6538 2.72903V16.6682H53.9245V2.72903H48.5632V0.350586H62.0151V2.72903H56.6538Z' fill='currentColor' />
        <path d='M75.2909 16.6487V9.37696H67.7657V16.6682H65.0168V0.350586H67.7657V6.99852H75.2714V0.350586H78.0008V16.6682H75.2909V16.6487Z' fill='currentColor' />
        <path d='M84.1029 2.72903V7.15448H91.7645V9.51343H84.1029V14.2703H92.4859V16.6487H81.354V0.350586H92.2519V2.72903H84.1029Z' fill='currentColor' />
        <path d='M110.919 14.2708V16.6492H100.158V0.331543H102.907V14.2708H110.919Z' fill='currentColor' />
        <path d='M133.357 16.6492L127.996 0.331543H130.823L133.045 7.13544C133.708 9.08498 134.391 11.3659 135.092 13.9588H135.131C135.677 11.8728 136.36 9.59186 137.139 7.13544L139.362 0.331543H142.189L136.847 16.6492H133.357Z' fill='currentColor' />
        <path d='M146.362 2.71019V7.13565H154.023V9.49459H146.362V14.2515H154.745V16.6299H143.613V0.312256H154.511V2.6907H146.362V2.71019Z' fill='currentColor' />
        <path d='M173.366 1.03326C174.496 1.7156 175.374 2.70986 175.998 3.97707C176.602 5.24427 176.914 6.76491 176.914 8.5C176.914 10.2351 176.602 11.7557 175.998 13.0229C175.393 14.2901 174.516 15.2844 173.366 15.9667C172.235 16.6491 170.89 17 169.35 17C167.809 17 166.464 16.6491 165.334 15.9667C164.203 15.2844 163.326 14.2901 162.702 13.0229C162.097 11.7557 161.785 10.2351 161.785 8.5C161.785 6.76491 162.097 5.24427 162.702 3.97707C163.306 2.70986 164.183 1.7156 165.334 1.03326C166.464 0.350918 167.809 0 169.35 0C170.89 0 172.235 0.350918 173.366 1.03326ZM165.899 4.07454C165.061 5.16629 164.632 6.62844 164.632 8.5C164.632 10.3716 165.061 11.8337 165.899 12.9255C166.737 14.0172 167.887 14.5436 169.35 14.5436C170.792 14.5436 171.943 13.9977 172.8 12.9255C173.639 11.8337 174.068 10.3716 174.068 8.5C174.068 6.62844 173.639 5.16629 172.8 4.07454C171.962 2.9828 170.812 2.45642 169.35 2.45642C167.887 2.45642 166.737 3.00229 165.899 4.07454Z' fill='currentColor' />
        <path d='M182.802 2.72948V7.7398H190.054V10.0987H182.782V16.6492H180.053V0.331543H190.619V2.70998H182.802V2.72948Z' fill='currentColor' />
        <path d='M207.44 1.46224C208.395 2.20307 208.863 3.21683 208.863 4.50352C208.863 6.35559 207.946 7.62279 206.094 8.30513V8.34412C208.18 8.8315 209.233 10.0987 209.233 12.1652C209.233 13.5494 208.746 14.6216 207.752 15.421C206.757 16.2203 205.393 16.6297 203.618 16.6297H197.555V0.312012H203.17C205.061 0.351003 206.484 0.721414 207.44 1.46224ZM200.304 7.27187H203.014C204.125 7.27187 204.944 7.07692 205.451 6.68701C205.958 6.2971 206.211 5.71224 206.211 4.93242C206.211 4.15261 205.958 3.56775 205.471 3.19733C204.983 2.80742 204.203 2.63196 203.131 2.63196H200.285V7.27187H200.304ZM200.304 14.3682H203.209C204.32 14.3682 205.139 14.1732 205.666 13.7638C206.192 13.3739 206.445 12.7501 206.445 11.9313C206.445 11.1125 206.172 10.4886 205.627 10.0792C205.081 9.66981 204.223 9.45536 203.034 9.45536H200.304V14.3682Z' fill='currentColor' />
        <path d='M221.905 1.52031C222.861 2.30013 223.348 3.41136 223.348 4.87352C223.348 5.88728 223.075 6.76458 222.529 7.48591C221.983 8.20724 221.242 8.67513 220.326 8.90907V8.94806C220.911 9.14302 221.359 9.45494 221.71 9.88384C222.042 10.3127 222.393 11.0341 222.763 12.0673L224.44 16.6682H221.437L220.053 12.5742C219.741 11.6384 219.351 10.9951 218.922 10.6442C218.494 10.2932 217.85 10.1178 217.031 10.1178H214.672V16.6682H211.924V0.350586H217.538C219.488 0.350586 220.95 0.740494 221.905 1.52031ZM214.672 7.85632H217.402C218.494 7.85632 219.293 7.62238 219.8 7.15449C220.326 6.6866 220.58 6.02375 220.58 5.20494C220.58 3.46985 219.468 2.61205 217.226 2.61205H214.653V7.85632H214.672Z' fill='currentColor' />
        <path d='M229.84 2.72948V7.15494H237.502V9.51388H229.84V14.2708H238.223V16.6492H227.091V0.331543H237.989V2.70998H229.84V2.72948Z' fill='currentColor' />
        <path d='M244.54 12.2428L243.019 16.6682H240.153L246.08 0.350586H249.764L255.691 16.6682H252.806L251.285 12.2428H244.54ZM250.037 8.57765C249.121 5.86779 248.419 3.7038 247.932 2.08568H247.893C247.074 4.73705 246.392 6.88155 245.807 8.57765L245.378 9.86435H250.486L250.037 8.57765Z' fill='currentColor' />
        <path d='M269.104 2.45609C270.585 3.85976 271.326 5.88728 271.326 8.49967C271.326 11.1121 270.585 13.1201 269.104 14.5432C267.622 15.9469 265.516 16.6682 262.787 16.6682H257.562V0.350586H262.787C265.516 0.350586 267.622 1.05242 269.104 2.45609ZM260.292 14.2703H262.748C264.639 14.2703 266.082 13.7829 267.037 12.8082C268.012 11.8334 268.499 10.3907 268.499 8.48017C268.499 6.56962 268.012 5.12696 267.037 4.15219C266.062 3.17742 264.639 2.69003 262.748 2.69003H260.292V14.2703Z' fill='currentColor' />
        <g ref={logoRef}>
          <path d='M120.023 2.63188C123.162 2.63188 125.891 4.13303 127.393 6.64793C126.905 3.00229 123.786 0 120.023 0C116.261 0 113.141 3.00229 112.654 6.64793C114.155 4.13303 116.885 2.63188 120.023 2.63188Z' fill='currentColor' />
          <path d='M120.023 14.3488C116.885 14.3488 114.155 12.8477 112.654 10.3328C113.141 13.9784 116.261 16.9807 120.023 16.9807C123.786 16.9807 126.905 13.9784 127.393 10.3328C125.891 12.8672 123.162 14.3488 120.023 14.3488Z' fill='currentColor' />
        </g>
      </svg>
      <svg className={cn(classes.mobileLogo, className)} viewBox='0 0 108 56'>
        <path d='M12.36 2.64769V7.50819H19.4144V9.79661H12.36V16.1512H9.69336V0.340363H19.9439V2.64769H12.36Z' fill='currentColor' />
        <path d='M32.4828 1.00236C33.5797 1.6643 34.4308 2.62884 35.036 3.85815C35.6223 5.08746 35.9249 6.56263 35.9249 8.24584C35.9249 9.92906 35.6223 11.4042 35.036 12.6335C34.4497 13.8629 33.5986 14.8274 32.4828 15.4893C31.3859 16.1513 30.0809 16.4917 28.5868 16.4917C27.0927 16.4917 25.7878 16.1513 24.6909 15.4893C23.5939 14.8274 22.7429 13.8629 22.1377 12.6335C21.5514 11.4042 21.2488 9.92906 21.2488 8.24584C21.2488 6.56263 21.5514 5.08746 22.1377 3.85815C22.724 2.62884 23.575 1.6643 24.6909 1.00236C25.7878 0.340425 27.0927 0 28.5868 0C30.0809 0 31.3859 0.340425 32.4828 1.00236ZM25.2393 3.95271C24.4261 5.01181 24.01 6.43025 24.01 8.24584C24.01 10.0614 24.4261 11.4799 25.2393 12.539C26.0526 13.5981 27.1684 14.1087 28.5868 14.1087C29.9863 14.1087 31.1022 13.5792 31.9343 12.539C32.7476 11.4799 33.1636 10.0614 33.1636 8.24584C33.1636 6.43025 32.7476 5.01181 31.9343 3.95271C31.1211 2.89361 30.0053 2.38297 28.5868 2.38297C27.1873 2.38297 26.0715 2.91252 25.2393 3.95271Z' fill='currentColor' />
        <path d='M48.2179 1.47511C49.1446 2.23161 49.6174 3.30962 49.6174 4.72806C49.6174 5.71151 49.3526 6.56257 48.8231 7.26233C48.2935 7.96209 47.5748 8.41599 46.686 8.64294V8.68077C47.2533 8.86989 47.6883 9.17249 48.0287 9.58857C48.3503 10.0046 48.6907 10.7044 49.05 11.7068L50.6765 16.1701H47.764L46.4212 12.1985C46.1186 11.2907 45.7403 10.6666 45.3243 10.3262C44.9082 9.98573 44.2841 9.81552 43.4897 9.81552H41.2013V16.1701H38.5347V0.340363H43.9815C45.8727 0.340363 47.2722 0.718612 48.2179 1.47511ZM41.1824 7.62167H43.8302C44.8893 7.62167 45.6647 7.39472 46.1564 6.94082C46.667 6.48692 46.9129 5.8439 46.9129 5.04957C46.9129 3.36636 45.8349 2.53421 43.66 2.53421H41.1635V7.62167H41.1824Z' fill='currentColor' />
        <path d='M64.6529 2.64769V16.1701H62.0051V2.64769H56.8042V0.340363H69.8538V2.64769H64.6529Z' fill='currentColor' />
        <path d='M82.7332 16.1512V9.09684H75.433V16.1701H72.7664V0.340363H75.433V6.78952H82.7143V0.340363H85.3621V16.1701H82.7332V16.1512Z' fill='currentColor' />
        <path d='M91.2816 2.64769V6.94082H98.7143V9.22923H91.2816V13.8439H99.414V16.1512H88.615V0.340363H99.1871V2.64769H91.2816Z' fill='currentColor' />
        <path d='M38.5159 33.6074V35.9147H28.0762V20.085H30.7428V33.6074H38.5159Z' fill='currentColor' />
        <path d='M60.2842 35.9147L55.0833 20.085H57.8256L59.9816 26.6854C60.6246 28.5767 61.2865 30.7894 61.9674 33.3048H62.0052C62.5348 31.2812 63.1967 29.0684 63.9532 26.6854L66.1092 20.085H68.8515L63.6695 35.9147H60.2842Z' fill='currentColor' />
        <path d='M72.8988 22.3923V26.6854H80.3314V28.9739H72.8988V33.5885H81.0312V35.8958H70.2322V20.0661H80.8043V22.3734H72.8988V22.3923Z' fill='currentColor' />
        <path d='M11.7736 40.5107C12.8705 41.1726 13.7216 42.1371 14.3268 43.3665C14.913 44.5958 15.2156 46.0709 15.2156 47.7541C15.2156 49.4374 14.913 50.9125 14.3268 52.1418C13.7405 53.3712 12.8894 54.3357 11.7736 54.9976C10.6766 55.6596 9.37168 56 7.87759 56C6.38351 56 5.07855 55.6596 3.98162 54.9976C2.8847 54.3357 2.03364 53.3712 1.42844 52.1418C0.842151 50.9125 0.539551 49.4374 0.539551 47.7541C0.539551 46.0709 0.842151 44.5958 1.42844 43.3665C2.01472 42.1371 2.86579 41.1726 3.98162 40.5107C5.07855 39.8487 6.38351 39.5083 7.87759 39.5083C9.37168 39.5083 10.6766 39.8487 11.7736 40.5107ZM4.53009 43.461C3.71685 44.5201 3.30077 45.9385 3.30077 47.7541C3.30077 49.5697 3.71685 50.9882 4.53009 52.0473C5.34332 53.1064 6.45916 53.617 7.87759 53.617C9.27712 53.617 10.393 53.0875 11.2251 52.0473C12.0383 50.9882 12.4544 49.5697 12.4544 47.7541C12.4544 45.9385 12.0383 44.5201 11.2251 43.461C10.4119 42.4019 9.29603 41.8913 7.87759 41.8913C6.45916 41.8913 5.34332 42.4208 4.53009 43.461Z' fill='currentColor' />
        <path d='M20.9272 42.156V47.0165H27.9626V49.3049H20.9082V55.6595H18.2605V39.8298H28.5111V42.1371H20.9272V42.156Z' fill='currentColor' />
        <path d='M45.7783 40.9266C46.705 41.6453 47.1589 42.6287 47.1589 43.8769C47.1589 45.6736 46.2701 46.9029 44.4734 47.5649V47.6027C46.497 48.0755 47.5183 49.3048 47.5183 51.3095C47.5183 52.6523 47.0455 53.6925 46.0809 54.4679C45.1164 55.2433 43.7925 55.6405 42.0715 55.6405H36.1897V39.8108H41.6365C43.471 39.8486 44.8516 40.2079 45.7783 40.9266ZM38.8564 46.5625H41.4852C42.5632 46.5625 43.3575 46.3734 43.8493 45.9951C44.341 45.6169 44.5868 45.0495 44.5868 44.293C44.5868 43.5365 44.341 42.9691 43.8682 42.6098C43.3954 42.2316 42.6389 42.0613 41.5987 42.0613H38.8374V46.5625H38.8564ZM38.8564 53.4467H41.6743C42.7523 53.4467 43.5467 53.2575 44.0573 52.8604C44.5679 52.4821 44.8138 51.8769 44.8138 51.0826C44.8138 50.2883 44.549 49.6831 44.0195 49.2859C43.4899 48.8888 42.6578 48.6807 41.5041 48.6807H38.8564V53.4467Z' fill='currentColor' />
        <path d='M59.8114 40.9834C60.7381 41.7399 61.2109 42.8179 61.2109 44.2363C61.2109 45.2198 60.9461 46.0708 60.4166 46.7706C59.887 47.4704 59.1683 47.9243 58.2795 48.1512V48.189C58.8468 48.3782 59.2818 48.6808 59.6222 49.0968C59.9438 49.5129 60.2842 50.2127 60.6435 51.215L62.27 55.6784H59.3575L58.0147 51.7068C57.7121 50.799 57.3338 50.1749 56.9178 49.8344C56.5017 49.494 55.8776 49.3238 55.0832 49.3238H52.7948V55.6784H50.1282V39.8486H55.575C57.4662 39.8486 58.8847 40.2269 59.8114 40.9834ZM52.7948 47.1299H55.4426C56.5017 47.1299 57.2771 46.903 57.7688 46.4491C58.2795 45.9952 58.5253 45.3522 58.5253 44.5578C58.5253 42.8746 57.4473 42.0425 55.2724 42.0425H52.7759V47.1299H52.7948Z' fill='currentColor' />
        <path d='M67.5087 42.156V46.4491H74.9413V48.7376H67.5087V53.3522H75.6411V55.6595H64.842V39.8298H75.4141V42.1371H67.5087V42.156Z' fill='currentColor' />
        <path d='M81.7687 51.3853L80.2936 55.6784H77.5134L83.2628 39.8486H86.8373L92.5867 55.6784H89.7876L88.3125 51.3853H81.7687ZM87.1021 47.8297C86.2132 45.2009 85.5323 43.1016 85.0595 41.5318H85.0217C84.2274 44.1039 83.5654 46.1843 82.998 47.8297L82.582 49.0779H87.537L87.1021 47.8297Z' fill='currentColor' />
        <path d='M105.598 41.8912C107.036 43.2529 107.754 45.2198 107.754 47.7541C107.754 50.2883 107.036 52.2363 105.598 53.6169C104.161 54.9786 102.118 55.6784 99.4706 55.6784H94.4021V39.8486H99.4706C102.118 39.8486 104.161 40.5295 105.598 41.8912ZM97.0498 53.3521H99.4328C101.267 53.3521 102.667 52.8793 103.594 51.9337C104.539 50.9881 105.012 49.5886 105.012 47.7351C105.012 45.8817 104.539 44.4822 103.594 43.5366C102.648 42.5909 101.267 42.1181 99.4328 42.1181H97.0498V53.3521Z' fill='currentColor' />
        <g ref={logoRef2}>
          <path d='M47.3479 22.3164C50.3928 22.3164 53.0406 23.7727 54.4968 26.2124C54.024 22.6758 50.998 19.7632 47.3479 19.7632C43.6978 19.7632 40.6718 22.6758 40.199 26.2124C41.6552 23.7727 44.303 22.3164 47.3479 22.3164Z' fill='currentColor' />
          <path d='M47.3479 33.6832C44.303 33.6832 41.6552 32.2269 40.199 29.7872C40.6718 33.3239 43.6978 36.2364 47.3479 36.2364C50.998 36.2364 54.024 33.3239 54.4968 29.7872C53.0406 32.2459 50.3928 33.6832 47.3479 33.6832Z' fill='currentColor' />
        </g>
      </svg>
    </>
  )
}

const useStyles = createUseStyles({
  desktopLogo: {
    display: 'none',
    width: 272,
    height: 17,
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    }
  },
  mobileLogo: {
    display: 'block',
    width: 108,
    height: 56,
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  }
})

import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import Content from './Content'
import PlayButton from './PlayButton'
import useFadeOutOnPlayAnimation from '../useFadeOutOnPlayAnimation'
import { useSelector } from 'react-redux'
import { isReady } from '../../redux/slices/layout'
import gsap from 'gsap'

export default () => {
  const classes = useStyles()
  const ref = useFadeOutOnPlayAnimation(false)
  const playButtonContainerRef = useRef()
  const ready = useSelector(isReady)

  useEffect(() => {
    if (ready) {
      gsap.to(playButtonContainerRef.current, { opacity: 1, ease: 'sine.inOut', duration: 0.5 })
    }
  }, [ready])

  return (
    <section className={classes.section}>
      <div className={classes.playButtonContainer} ref={playButtonContainerRef}>
        {ready && <PlayButton />}
      </div>
      <section className={classes.content} ref={ref}>
        <Content />
      </section>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    position: 'relative',
    alignSelf: 'flex-end'
  },
  playButtonContainer: {
    opacity: 0
  }
})

import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { getSocialLinks } from '../../redux/slices/content'
import AnimatedLink from '../AnimatedLink'
import theme from '../../style/theme'

export default forwardRef(({ className }, ref) => {
  const classes = useStyles()
  const socialLinks = useSelector(getSocialLinks)

  return (
    <div className={cn(classes.container, className)} ref={ref}>
      <span className={classes.label}>Follow</span>
      <ul className={classes.list}>
        {socialLinks.map((link, i) => (
          <li key={i} className={classes.item}><AnimatedLink className={classes.link} link={link} /></li>
        ))}
      </ul>
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    display: 'flex'
  },
  label: {
    width: '50%',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: 150
    }
  },
  list: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    margin: [0, theme.spacing(-1)]
  },
  item: {
    margin: [0, theme.spacing(1)]
  },
  link: {
    fontSize: 'inherit'
  }
})

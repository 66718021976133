import * as breakpoints from './breakpoints'
import * as fonts from './fonts'
import * as typography from './typography'

const colors = {
  black: '#111',
  white: '#FFF'
}

const globalStyles = {
  '@font-face': fonts.faces,
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ':root': {
    '--scrollbar-width': '0px'
  },
  html: {
    lineHeight: 1.2,
    height: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden'
  },
  body: {
    backgroundColor: colors.black,
    color: colors.white,
    height: '100%',
    width: '100%',
    position: 'fixed',
    overflow: 'hidden',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    ...typography.baseBodyStyles
  },
  '#root': {
    height: '100%'
  },
  'h1, h2, h3, h4, h5, h6': {
    margin: 0
  },
  ...typography.headings,
  p: {
    margin: [0, 0, '1.5em']
  },
  a: {
    color: colors.white,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  button: {
    ...typography.baseBodyStyles
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  '.clearfix::after': {
    content: '""',
    clear: 'both',
    display: 'table'
  }
}

export default {
  global: globalStyles,
  breakpoints,
  typography,
  colors,
  fonts,
  spacing: (value) => `${value}rem`,
  zIndex: {
    playButton: 85,
    videoControls: 85,
    overlay: 86,
    dialog: 90,
    menu: 95,
    header: 100,
    mobileVideoPlayer: 150,
    pageLoadingBar: 200,
    cursor: 1000
  }
}

import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { isMuted, isPlaying, setMuted } from '../../redux/slices/episodes'
import detectIt from 'detect-it'
import gsap from 'gsap'
import theme from '../../style/theme'
import AnimatedLink from '../AnimatedLink'
import WatchNowLink from '../WatchNowLink'

export default () => {
  const classes = useStyles()
  const playing = useSelector(isPlaying)
  const muted = useSelector(isMuted)
  const dispatch = useDispatch()
  const ref = useRef()

  useEffect(() => {
    if (detectIt.primaryInput !== 'touch') {
      gsap.to(ref.current, { autoAlpha: playing ? 1 : 0, y: playing ? 0 : '100%', duration: 0.5, ease: 'sine.out' })
    }
  }, [playing])

  const onToggleMuted = useCallback(() => {
    dispatch(setMuted(!muted))
  }, [muted])

  return (
    <div ref={ref} className={classes.container}>
      <AnimatedLink onClick={onToggleMuted} text={`Sound ${muted ? 'Off' : 'On'}`} />
      <WatchNowLink className={classes.link} />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: 0,
    transform: 'translate(0, 100%)',
    padding: theme.spacing(1),
    display: 'flex',
    zIndex: theme.zIndex.videoControls,
    justifyContent: 'space-between'
  }
})

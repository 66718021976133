import inDOM from 'dom-helpers/canUseDOM'
import { rehydrated } from '../actions'
import { playEpisode, pauseEpisode } from '../slices/episodes'
import detectIt from 'detect-it'
import theme from '../../style/theme'

var playPromise = null

export default store => next => action => {
  if (!inDOM) return next(action)

  const videoPlayer = document.getElementById('full-video-player')
  const overlay = document.getElementById('full-video-player-overlay')

  if (!videoPlayer) return next(action)

  if (action.type === rehydrated.toString()) {
    videoPlayer.addEventListener('pause', () => {
      store.dispatch(pauseEpisode())
    })
    videoPlayer.addEventListener('ended', () => {
      store.dispatch(pauseEpisode())
    })
  }

  if (action.type === playEpisode.toString()) {
    playPromise = videoPlayer.play()
    if (detectIt.primaryInput === 'touch') {
      videoPlayer.style.visibility = 'visible'
      videoPlayer.style.zIndex = theme.zIndex.mobileVideoPlayer
      videoPlayer.style.pointerEvents = 'all'
      overlay.style.opacity = 1
      overlay.style.pointerEvents = 'all'
      overlay.style.zIndex = theme.zIndex.mobileVideoPlayer - 1
    }
  }
  if (action.type === pauseEpisode.toString()) {
    if (playPromise) {
      playPromise.then(() => {
        videoPlayer.pause()
      })
    }
    if (detectIt.primaryInput === 'touch') {
      videoPlayer.style.visibility = 'hidden'
      videoPlayer.style.zIndex = -1
      videoPlayer.style.pointerEvents = 'none'
      overlay.style.opacity = 0
      overlay.style.pointerEvents = 'none'
    }
  }

  return next(action)
}

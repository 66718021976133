import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedEpisodeInMenu, selectEpisodeInMenu, navigateToEpisode } from '../../redux/slices/episodes'
import detectIt from 'detect-it'

export default ({ ready, episode, disabled }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedEpisode = useSelector(getSelectedEpisodeInMenu)
  const selected = useSelector(getSelectedEpisodeInMenu) === episode

  const onMouseEnter = useCallback(() => {
    if (!disabled) {
      dispatch(selectEpisodeInMenu(episode))
    }
  }, [episode, disabled])
  const onClick = useCallback(() => {
    if (!disabled) {
      dispatch(navigateToEpisode(episode))
    }
  }, [episode, disabled])

  return (
    <li onClick={onClick} onMouseEnter={detectIt.primaryInput === 'touch' ? null : onMouseEnter} className={cn(classes.container, { selected: selected || selectedEpisode === null })}>
      <span className={classes.linkContainer}>
        <span className={classes.link}>
          {episode.title}
          <span className={classes.episodeNumber}>{episode.episodeNumber}</span>
        </span>
      </span>
      <span className={classes.line} />
    </li>
  )
}

const useStyles = createUseStyles({
  container: {
    padding: 0,
    margin: 0,
    display: 'block',
    position: 'relative',
    fontSize: 38,
    textTransform: 'uppercase',
    lineHeight: 1,
    paddingBottom: theme.spacing(0.5),
    overflow: 'hidden',
    cursor: 'none',
    [theme.breakpoints.up(400)]: {
      fontSize: 44
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(1),
      fontSize: 110
    },
    opacity: 0.1,
    transition: 'opacity 0.2s ease-in-out',
    '&.selected': {
      opacity: 1,
      zIndex: 2
    }
  },
  linkContainer: {
    display: 'block',
    overflow: 'hidden'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between'
  },
  line: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'currentColor',
    height: 2,
    [theme.breakpoints.up('sm')]: {
      height: 3
    }
  },
  episodeNumber: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    }
  }
})

import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import VideoContainer from './VideoContainer'
import { getEpisodes, getPosterImage } from '../../redux/slices/content'
import { isVideoReady } from '../../redux/slices/layout'
import VideoControls from './VideoControls'
import ResponsiveImage from '../ResponsiveImage'

const VideoPlayer = forwardRef(({ className }, ref) => {
  const posterImage = useSelector(getPosterImage)
  const classes = useStyles()
  const loaded = useSelector(isVideoReady)
  const episodes = useSelector(getEpisodes).episodes

  return (
    <section className={cn(classes.section, className)}>
      {posterImage && <ResponsiveImage {...posterImage} className={classes.posterImage} />}
      <VideoContainer episodes={episodes} className={cn(classes.canvas, { loaded })} />
      <VideoControls />
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.35s ease-out',
    '&.loaded': {
      opacity: 1
    }
  },
  posterImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
}, { name: 'VideoPlayer' })

export default VideoPlayer

import React, { useRef, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import useHlsPlayer from '../../hooks/useHlsPlayer'
import { getCurrentEpisode, isMuted } from '../../redux/slices/episodes'
import detectIt from 'detect-it'
import theme from '../../style/theme'
import Color from 'color'
import { getHlsSourceFromVimeo } from '../../helpers/video'
import get from 'lodash/get'
import { fullPlayerReady } from '../../redux/slices/layout'

const FullVideoPlayer = () => {
  const playerRef = useRef()
  const [playsInline, setPlaysInline] = useState(true)
  const episode = useSelector(getCurrentEpisode)
  const url = get(getHlsSourceFromVimeo(episode.vimeoVideo), ['url'])
  const dispatch = useDispatch()

  const classes = useStyles()
  const muted = useSelector(isMuted)

  useEffect(() => {
    dispatch(fullPlayerReady(false))
  }, [url])

  useHlsPlayer(playerRef, url, () => { dispatch(fullPlayerReady(true)) })

  useEffect(() => {
    if (detectIt.primaryInput === 'touch') {
      setPlaysInline(false)
    }
  }, [])

  return (
    <>
      <div className={classes.overlay} id='full-video-player-overlay' />
      <video
        ref={playerRef}
        src={url}
        className={classes.video}
        controls={!playsInline}
        crossOrigin='anonymous'
        playsInline={playsInline}
        id='full-video-player'
        muted={muted}
      />
    </>
  )
}

const useStyles = createUseStyles({
  video: {
    position: 'absolute',
    visibility: 'hidden',
    width: '100%',
    top: '50%',
    transform: 'translate(0, -50%)',
    backgroundColor: theme.colors.black,
    pointerEvents: 'none',
    zIndex: -1
  },
  overlay: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.15s ease-in-out',
    backgroundColor: Color(theme.colors.black).alpha(0.8).string()
  }
}, { name: 'FullVideoPlayer' })

export default FullVideoPlayer

import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { closeWatchNowDropdown, getWatchNowDropdownPosition, getWatchNowLinks, isWatchNowDropdownOpen } from '../redux/slices/episodes'
import theme from '../style/theme'
import gsap from 'gsap'
import AnimatedLink from './AnimatedLink'
import Color from 'color'
import { isCurrentBreakpointAtLeast } from '../redux/slices/layout'

const isCurrentBreakpointSmall = state => !isCurrentBreakpointAtLeast(state, 'md')

const LinkWithIcon = ({ text, classes }) => {
  return (
    <span className={classes.linkWithIconContainer}>
      {text}
      <svg className={classes.linkIcon} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M2.5 1H9V7.5' stroke='white' />
        <path d='M9 1L2 8' stroke='white' />
      </svg>
    </span>
  )
}

export default () => {
  const classes = useStyles()
  const open = useSelector(isWatchNowDropdownOpen)
  const position = useSelector(getWatchNowDropdownPosition)
  const small = useSelector(isCurrentBreakpointSmall)
  const overlayRef = useRef()
  const containerRef = useRef()
  const dispatch = useDispatch()
  const watchNowLinks = useSelector(getWatchNowLinks)

  useEffect(() => {
    if (overlayRef.current) {
      gsap.set(overlayRef.current, { pointerEvents: open ? 'all' : 'none', zIndex: theme.zIndex.overlay })
      if (small && position) {
        const { top, left } = position
        gsap.set(containerRef.current, { top, left, right: 'auto', bottom: 'auto' })
      } else {
        gsap.set(containerRef.current, { top: 'auto', left: 'auto', right: theme.spacing(1), bottom: theme.spacing(1) })
      }
      gsap.to(overlayRef.current, { autoAlpha: open ? 1 : 0, duration: 0.25 })
    }
  }, [open, small])

  const onOutsideClick = useCallback(() => {
    dispatch(closeWatchNowDropdown())
  }, [])

  return (
    <div className={classes.overlay} ref={overlayRef} onClick={onOutsideClick}>
      <div className={classes.container} ref={containerRef}>
        <AnimatedLink text='Watch Now' className={classes.link} />
        <div className={classes.dropDownBox}>
          <ul className={classes.list}>
            {watchNowLinks && watchNowLinks.map((link, i) => (
              <li
                key={link.url}
                className={classes.item}
              >
                <AnimatedLink className={classes.link} to={link.url} text={<LinkWithIcon classes={classes} text={link.text} />} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Color(theme.colors.black).alpha(0.8).string(),
    opacity: 0,
    pointerEvents: 'none'
  },
  container: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1)
  },
  dropDownBox: {
    position: 'absolute',
    bottom: '100%',
    right: 0,
    left: 0
  },
  list: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 0,
    margin: 0
  },
  item: {
    marginBottom: theme.spacing(1),
    display: 'block',
    whiteSpace: 'nowrap'
  },
  link: {
    fontSize: 12
  },
  linkWithIconContainer: {
    display: 'flex'
  },
  linkIcon: {
    width: '0.9em',
    height: '0.9em',
    marginLeft: 8,
    marginTop: '0.15em'
  }
})

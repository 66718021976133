import inDOM from 'dom-helpers/canUseDOM'
import { createSlice } from '@reduxjs/toolkit'
import { rehydrated } from '../actions'
import { current, keys } from '../../style/breakpoints'

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    breakpoint: current(),
    breakpointSet: inDOM,
    ready: false,
    fullPlayerReady: false,
    preload: {
      video: false
    }
  },
  reducers: {
    breakpointChanged: (state, action) => {
      state.breakpoint = action.payload
      state.breakpointSet = true
    },
    videoLoaded: (state) => {
      state.preload.video = true
      // state.ready = !find(state.preload, x => !x)
    },
    siteLoaded: (state) => {
      state.ready = true
    },
    fullPlayerReady: (state, action) => {
      state.fullPlayerReady = action.payload
    }
  },
  extraReducers: {
    [rehydrated]: (state, action) => {
      state.breakpointSet = true
    }
  }
})

export const {
  breakpointChanged,
  videoLoaded,
  siteLoaded,
  fullPlayerReady
} = layoutSlice.actions

export const getCurrentBreakpoint = state => state.layout.breakpoint
export const isCurrentBreakpointAtLeast = (state, breakpoint) => (
  keys.indexOf(getCurrentBreakpoint(state)) >= keys.indexOf(breakpoint)
)
export const isBreakpointSet = state => state.layout.breakpointSet
export const isCurrentBreakpointMobile = state => !isCurrentBreakpointAtLeast(state, 'md')

export const isReady = state => state.layout.ready
export const isVideoReady = state => state.layout.preload.video

export const isFullPlayerReady = state => state.layout.fullPlayerReady

export default layoutSlice.reducer

import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuItems, isDialogOpen } from '../../redux/slices/content'
import theme from '../../style/theme'
import Logo from './Logo'
import AnimatedLink from '../AnimatedLink'
import useFadeOutOnPlayAnimation from '../useFadeOutOnPlayAnimation'
import { isMenuOpen, toggleMenu } from '../../redux/slices/episodes'
import { navigateHome } from '../../redux/slices/location'
import { stage } from '../../redux/middlewares/stage'
import { ACT_NAMES, isActRunning } from '../../redux/slices/stage'

export default () => {
  const dialogOpen = useSelector(isDialogOpen)
  const classes = useStyles({ dialogOpen })
  const menuItems = useSelector(getMenuItems)
  const ref = useFadeOutOnPlayAnimation()
  const menuOpen = useSelector(isMenuOpen)
  const dispatch = useDispatch()
  const navRef = useRef()
  const episodesRef = useRef()
  const logoRef = useRef()
  const animatingMenuToEpisode = useSelector(isActRunning(ACT_NAMES.actMenuToEpisode))

  const onEpisodesClick = useCallback(() => {
    if (!animatingMenuToEpisode) {
      dispatch(toggleMenu())
    }
  }, [animatingMenuToEpisode])

  const onHomeClick = useCallback(() => {
    dispatch(navigateHome())
    dispatch()
  }, [])

  useEffect(() => {
    return stage.addHeaderActors(logoRef.current, navRef.current, episodesRef.current)
  }, [])

  return (
    <header className={classes.header} ref={ref} id='header'>
      <nav>
        <ul className={classes.navList} ref={navRef}>
          {menuItems.map((item, i) => (
            <li key={i}>
              <AnimatedLink className={classes.link} link={dialogOpen ? null : item} to={dialogOpen ? '/' : null} text={dialogOpen ? 'Close' : item.text} />
            </li>
          ))}
        </ul>
      </nav>
      <div className={classes.middle}>
        <a ref={logoRef} className={classes.logoLink} onClick={onHomeClick} aria-label='For The Love Of Bread'><Logo className={classes.logo} /></a>
      </div>
      <div className={classes.right} ref={episodesRef}>
        <AnimatedLink nonLinkTag='button' className={classes.episodeLink} onClick={onEpisodesClick} text={!menuOpen ? 'Episodes' : 'Close'} />
      </div>
    </header>
  )
}

const useStyles = createUseStyles({
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    padding: theme.spacing(1),
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr 1fr',
    zIndex: theme.zIndex.header,
    color: ({ dialogOpen }) => dialogOpen ? theme.colors.black : 'currentColor',
    transition: 'color 0.25s ease-in-out',
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: '1fr 2fr 1fr'
    }
  },
  navList: {
    margin: 0,
    padding: 0,
    display: 'flex',
    listStyle: 'none',
    textTransform: 'uppercase',
    opacity: 0,
    transform: 'translate(0, -100%)'
  },
  logo: {
    margin: 'auto',
    cursor: 'pointer'
  },
  right: {
    textAlign: 'right',
    opacity: 0,
    transform: 'translate(0, -100%)'
  },
  logoLink: {
    display: 'block',
    color: 'currentColor',
    opacity: 0
  },
  link: {
    minHeight: 42,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto'
    }
  },
  episodeLink: {
    margin: [0, 0, 0, 'auto'],
    minHeight: 42,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto'
    }
  }
})

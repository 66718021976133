import React, { forwardRef, useRef, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import {
  // getCurrentEpisodeIndex,
  getSelectedEpisodeIndexInMenu,
  isMenuOpen
} from '../../redux/slices/episodes'
import detectIt from 'detect-it'
import { useCursorPosition } from '../Cursor'
import gsap from 'gsap'
import useRaf from '../../hooks/useRaf'
import { ACT_NAMES, isActRunning } from '../../redux/slices/stage'
import composeRefs from '../../helpers/composeRefs'
import PreviewVideoCanvas from './PreviewVideoCanvas'
import theme from '../../style/theme'

export default forwardRef((props, ref) => {
  const classes = useStyles()
  const containerRef = useRef()
  const menuOpen = useSelector(isMenuOpen)
  const selectedIndex = useSelector(getSelectedEpisodeIndexInMenu)
  // const currentIndex = useSelector(getCurrentEpisodeIndex)
  const isActMenuToEpisodeRunning = useSelector(isActRunning(ACT_NAMES.actMenuToEpisode))
  const localsRef = useRef({ scrollY: 0, cursor: { last: { x: 0, y: 0 } }, update: true })

  // const index = selectedIndex !== -1 ? selectedIndex : currentIndex

  useRaf(useCallback(() => {
    if (containerRef.current && detectIt.primaryInput !== 'touch' && localsRef.current.update) {
      localsRef.current.update = false
      const { cursor, scrollY } = localsRef.current
      containerRef.current.style.transform = `translate3d(${cursor.last.x - 225}px, ${cursor.last.y - 125 + scrollY}px, 0)`
    }
  }, []), menuOpen && !isActMenuToEpisodeRunning)

  useEffect(() => {
    if (menuOpen && containerRef.current) {
      const onScroll = () => {
        localsRef.current.update = true
        localsRef.current.scrollY = containerRef.current.parentNode.scrollTop
      }
      containerRef.current.parentNode.addEventListener('scroll', onScroll)
      onScroll()
      return () => {
        containerRef.current.parentNode.removeEventListener('scroll', onScroll)
      }
    }
  }, [menuOpen])

  useCursorPosition(useCallback((data) => {
    if (containerRef.current && detectIt.primaryInput !== 'touch') {
      localsRef.current.update = true
      localsRef.current.cursor = data
    }
  }, []), menuOpen)

  useEffect(() => {
    if (menuOpen && containerRef.current && detectIt.primaryInput !== 'touch') {
      gsap.to(containerRef.current, { opacity: selectedIndex !== -1 ? 1 : 0, duration: 0.15 })
    }
  }, [menuOpen, selectedIndex])

  return (
    <div className={classes.container} ref={composeRefs(containerRef, ref)}>
      <PreviewVideoCanvas />
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    width: 450,
    height: 250,
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    opacity: 0,
    zIndex: 1,
    backgroundColor: theme.colors.black
  }
}, { name: 'PreviewVideoSource' })

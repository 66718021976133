import React, { useEffect, forwardRef, useRef } from 'react'
import get from 'lodash/get'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import round from '../helpers/round'
import defaultImage from '../images/spacer'
import theme from '../style/theme'

const Picture = forwardRef(({ classes, alt, sizes, disableLazy }, ref) => {
  const hasWebp = !!get(sizes, [0, 'webpUrl'])
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  const srcName = disableLazy ? 'srcSet' : 'data-srcset'
  return (
    <picture>
      {hasWebp && <source {...{ [srcName]: srcset('webpUrl') }} type='image/webp' />}
      {sizes && <source {...{ [srcName]: srcset('url') }} />}
      <img
        ref={ref}
        data-sizes='auto'
        alt={alt}
        className={cn((sizes && !disableLazy && 'lazyload') || (disableLazy && 'lazyloaded'), classes.image)}
        src={defaultImage}
      />
    </picture>
  )
})

const NoScript = ({ classes, alt, sizes }) => {
  if (sizes) {
    return (
      <noscript>
        <img
          src={sizes[sizes.length - 1].url}
          srcSet={sizes.map(item => (`${item.url} ${item.width}w`)).join()}
          className={`${classes.image} lazyloaded`}
          alt={alt}
        />
      </noscript>
    )
  }
  return null
}

const ResponsiveImage = React.forwardRef(function ResponsiveImage (props, ref) {
  const { disableLazy, aspect, children, className, alt, sizes, palette, style, title, credit, onImageLoaded } = props
  const classes = useStyles({ aspect, palette })
  const pictureRef = useRef()

  useEffect(() => {
    if (onImageLoaded) {
      const onLoaded = (e) => {
        if (pictureRef.current === e.target) {
          onImageLoaded(e)
        }
      }
      document.addEventListener('lazyloaded', onLoaded)
      return () => {
        document.removeEventListener('lazyloaded', onLoaded)
      }
    }
  }, [])

  return (
    <div className={cn(classes.container, className, { withCaption: title })} ref={ref} style={style}>
      <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} ref={pictureRef} />
      <NoScript classes={classes} alt={alt} sizes={sizes} />
      {children}
      {(title || credit) && (
        <span className={cn(classes.captionAndCredit)}>
          {credit && <span className={classes.credit}>{credit}</span>}
          {title && <span className={classes.caption}>{title}</span>}
          {}
        </span>
      )}
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    '& picture::before': {
      display: 'block',
      content: ({ aspect }) => aspect ? '""' : undefined,
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    },
    '&.withCaption': {
      overflow: 'visible',
      marginBottom: theme.spacing(2)
    }
  },
  image: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 0.5s ease-out',
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"', // object-fit polyfill
    '&.lazyloaded': {
      opacity: 1
    }
  },
  link: {
    textDecoration: 'none'
  },
  captionAndCredit: {
    position: 'absolute',
    top: '100%',
    left: 0,
    fontSize: 11,
    textTransform: 'uppercase',
    lineHeight: 1.2,
    marginTop: theme.spacing(0.5),
    display: 'flex',
    letterSpacing: '0.03em'
  },
  credit: {
    display: 'block',
    marginRight: theme.spacing(0.5),
    minWidth: 150
  },
  caption: {
    display: 'block'
  },
  parallax: {
    height: '100%'
  }
}, { name: 'ResponsiveImage' })

export default ResponsiveImage

import get from 'lodash/get'
import compact from 'lodash/compact'

function resolveSlug (document) {
  const slug = get(document, ['slug'], document)
  if (slug !== 'home') { // home resolves to `/`
    return slug
  }
}

export function resolveInternalLinkUrl (reference) {
  if (!reference) return null
  const { parent, slug } = reference
  const parts = [
    resolveSlug(get(parent, ['parent'])),
    resolveSlug(parent),
    resolveSlug(slug)
  ]
  return `/${compact(parts).join('/')}`
}

export function resolveInternalLink (reference, text) {
  if (!reference) {
    return reference
  }
  return {
    text: text || reference.title,
    url: resolveInternalLinkUrl(reference)
  }
}

export function resolveLink (link) {
  if (get(link, ['type']) === 'internalLink' ||
    get(link, ['_type']) === 'internal_link') {
    return resolveInternalLink(link.reference, link.text)
  }
  return link
}

import React, { useRef, forwardRef, useMemo, Suspense, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import { Canvas, useThree } from 'react-three-fiber'
import { VideoTexture } from 'three'
import { getCoverVideoSize } from '../../helpers/video'
import gsap from 'gsap'

import { ReactReduxContext, useSelector } from 'react-redux'
import { getSelectedEpisodeIndexInMenu } from '../../redux/slices/episodes'
import forwardContext from '../../helpers/forwardContext'

import { getEpisodes } from '../../redux/slices/content'
import theme from '../../style/theme'

import '../VideoPlayer/VideoTransitionMaterial'

const WrappedCanvas = forwardContext(ReactReduxContext)(Canvas)

const Plane = forwardRef(({ map, materialRef, ...props }, ref) => {
  return (
    <mesh {...props} ref={ref}>
      <planeBufferGeometry attach='geometry' args={[1, 1, 32, 32]} />
      <videoTransitionMaterial ref={materialRef} attach='material' map={map} textureIndex={0} blendFactor={0.3} />
    </mesh>
  )
})

const PreviewVideoMesh = ({ episodes }) => {
  const meshRef = useRef()
  const materialRef = useRef()
  const { size, camera } = useThree()
  const selectedEpisodeIndexInMenu = useSelector(getSelectedEpisodeIndexInMenu)

  const width = size.width / camera.zoom
  const height = size.height / camera.zoom

  const previewVideoTextures = useMemo(() => {
    const videosContainer = document.getElementById('videos-sources')
    return map(videosContainer.children, (video) => new VideoTexture(video))
  }, [])

  // This is the effect to size up the video inside the canvas
  useEffect(() => {
    const timeline = gsap.timeline()
    const size = getCoverVideoSize(width, height, episodes[0].backgroundVideo)
    timeline.set(meshRef.current.scale, { ...size })
    return () => {
      timeline.kill()
    }
  }, [width, height])

  useEffect(() => {
    materialRef.current.map = previewVideoTextures[selectedEpisodeIndexInMenu]
  }, [selectedEpisodeIndexInMenu, previewVideoTextures])

  return (
    <Plane position={[0, 0, 0]} ref={meshRef} materialRef={materialRef} />
  )
}

const PreviewVideoCanvas = forwardRef((props, ref) => {
  const classes = useStyles()
  const canvasRef = useRef()
  const episodes = useSelector(getEpisodes).episodes

  return (
    <div className={classes.canvas} ref={canvasRef}>
      <WrappedCanvas orthographic camera={{ zoom: 1, position: [0, 0, 1], near: 1, far: 1000 }} resize={{ scroll: false }}>
        <color attach='background' args={[theme.colors.black]} />
        <Suspense fallback={null}>
          <PreviewVideoMesh episodes={episodes} />
        </Suspense>
      </WrappedCanvas>
    </div>
  )
})

const useStyles = createUseStyles({
  canvas: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
}, { name: 'PreviewVideoCanvas' })

export default PreviewVideoCanvas

export function configureLazySizes () {
  window.lazySizesConfig = window.lazySizesConfig || {}
  window.lazySizesConfig.init = false
  window.lazySizesConfig.expand = 500
  window.lazySizesConfig.hFac = 2
}

export default function () {
  configureLazySizes()
  require('lazysizes/plugins/attrchange/ls.attrchange')
  const lazysizes = require('lazysizes')
  lazysizes.init()
}

import { useEffect } from 'react'

export default (callback, enabled = true) => {
  useEffect(() => {
    if (enabled) {
      let animationId
      const loop = (time) => {
        callback(time)
        animationId = window.requestAnimationFrame(loop)
      }
      animationId = window.requestAnimationFrame(loop)
      return () => {
        window.cancelAnimationFrame(animationId)
      }
    }
  }, [callback, enabled])
}
